.accordion {
	> .card {
	  &:not(:first-of-type) {
		.card-header:first-child {
		  border-radius: 0;
		}
  
		&:not(:last-of-type) {
		  border-bottom: 0;
		  border-radius: 5px;
		}
	  }
  
	  &:first-of-type {
		border-bottom: 0;
	  }
  
	  &:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	  }
	}
  
	.card {
	  border-width: 0;
  
	  + .card {
		margin-top: 1px;
	  }
	}
  
	.card-header {
	  padding: 0 !important;
	  background-color: transparent;
	  font-weight: 400;
  
	  a {
		display: block;
		padding: 14px 20px;
		color: $default-color;
		position: relative;
		font-weight: 400;
		font-size: 14px;
		background-color: #f3f3f3;
		border:1px solid $border;
		border-radius: 0px;
		width: 100%;
  
		&.collapsed {
		  background-color: #f3f3f3;
		  border-bottom-color:$border ;
		}
	  }
	}
  
	.card-body {
	  padding: 20px 20px;
	  background-color:$white;
	  transition: none;
	  border: 1px solid #ededf5;
	  border-bottom-left-radius: 5px;
	  border-bottom-right-radius: 5px;
	  border-top: 0px;
	}
}
  /* ###### 3.1 Accordion ###### */
.accordion-primary {
	.card {
		background-color: $primary-03;
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: $primary-09;
			color: $white;

			&:hover, &:focus {
			color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($primary, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-secondary {
	.card {
		background-color: rgba($secondary, 0.45);
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($secondary, 0.65);
			color: $white;

			&:hover, &:focus {
			  color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($secondary, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-info {
	.card {
		background-color: rgba($info, 0.45);
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($info, 0.65);
			color: $white;

			&:hover, &:focus {
			  color: $white;
			}
			&.accor-style2{
				border-radius: 5px ;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($info, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-warning {
	.card {
		background-color: rgba($warning, 0.45);
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($warning, 0.65);
			color: $white;

			&:hover, &:focus {
			  color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($warning, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-success {
	.card {
		background-color: rgba($success, 0.45);
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($success, 0.65);
			color: $white;

			&:hover, &:focus {
			  color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($success, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-danger {
	.card {
		background-color: rgba($danger, 0.45);
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($danger, 0.65);
			color: $white;

			&:hover, &:focus {
			  color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: rgba($danger, 0.0);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion-dark {
	.card {
	background-color: #ebe2fc;
	}
	.card-header{
		padding: 0px !important;
		a {
			transition: all ease 0.3s;
			padding: 14px 20px;
			width: 100%;
			background-color: rgba($gray-dark, 0.65);
			color: $gray-light;

			&:hover, &:focus {
			color: $white;
			}
			&.accor-style2{
				border-radius: 5px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
				&.collapsed, &.collapse{
					border-radius: 5px;
			
					&.active{
						border-bottom-left-radius: 0px;
						border-bottom-right-radius: 0px;
						
					}
				}
			}
		}
	}
	.card-body{
		background-color: $gray-light;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}
.accordion > .card{
border:0 !important;
}

  	
  /* ############### CUSTOM ELEMENT STYLES ############### */
  
.main-accordion {
.accordion-item {
	background-color: $gray-100;
	border: 1px solid #dee2ef;
	position: relative;
	transition: all 0.2s ease-in-out;

	&:hover, &:focus {
	cursor: pointer;
	background-color: #f1f2f6;
	border-color: #bcc4de;
	z-index: 4;
	}

	&.active {
	background-color: $white;
	z-index: 5;
	border-color: #bcc4de;

	.accordion-title {
		color: $primary;
	}

	.accordion-body {
		display: block;
	}
	}

	+ .accordion-item {
	margin-top: -1px;
	}
}

.accordion-header {
	display: flex;
	align-items: center;
	padding: 15px 20px;
}

.accordion-title {
	font-weight: 700;
	color: $default-color;
	margin: 0;
	transition: all 0.2s ease-in-out;
}

.accordion-body {
	display: none;
	padding: 0 20px 20px;
}
}

@media (prefers-reduced-motion: reduce) {
.main-accordion .accordion-item {
	transition: none;
}
}

@media (prefers-reduced-motion: reduce) {
.main-accordion .accordion-title {
	transition: none;
}
}
  
.panel-heading1 .panel-title1 a {
  display: block;
  color: $gray-100;
}

.panel-title1 {
  margin-bottom: 0;
}

.panel-heading1  {
  display: block;
  padding: 14px 20px;
  background-color: #f3f3f3;
  &.collapsed{
	  border: 1px solid $border;
  }
  &.active{
	border: 1px solid  $border;
	border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.panel-title1 a {
  display: block;
  color: $white;
  padding: 15px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
}

.panel-heading1.collapsed:before {
  content: "\e9af" !important;
  position: absolute;
  font-family: 'feather' !important;
  right: 10px;
  top: 9px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}
.panel-heading1.active:before{
  content: "\e994" !important;
  position: absolute;
  font-family: 'feather' !important;
  right: 10px;
  top: 6px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}

.accor {
  &.bg-primary h4 a {
    color: $white;
  }

  h4 a {
    padding: 14px 20px;
    display: block;
    font-size: 15px;
    background-color: #f3f3f3;
	font-weight:400;
	border-radius: 5px;
	border: 1px solid $border;

	&.active{
		border-bottom: 0px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
  }
}
.accordion {
	.accor-basic, .accor-style2{
		border-radius: 5px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		
		&.collapsed, &.collapse{
			border-radius: 5px;
	
			&.active{
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				
			}
		}
	}
}
#accordion11 .panel-body {
    padding: 15px;
    border: 1px solid #ededf5;
    border-radius: 0px 0px 5px 5px;
}
.panel-heading2 {
    display: block;
    padding: 12px;
    background: #ffffff;
    color: $default-color;
}
.panel-heading2.collapsed:before {
    content: "\e92d";
    position: absolute;
    font-family: 'feather' !important;
    right: 10px;
    top: 6px;
    font-size: 16px;
	line-height: 2.3;
    transition: all 0.5s;
    transform: scale(1);
}
.panel-group2 .panel-collapse.collapse.show:before {
    content: "\e930";
    position: absolute;
    font-family: 'feather' !important;
    right: 10px;
    top: 6px;
    font-size: 16px;
	line-height: 2.3;
	transition: all 0.5s;
    transform: scale(1);
    color: $primary;
}
.accor h4 a.collapsed {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.accor h4 a[aria-expanded="true"] {
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom: 0px;
}
.panel-group1 a[aria-expanded="true"] {
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
}
.acc-header a[aria-expanded="true"] {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}