h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: normal;
	line-height: 1.1;
}
h1, .h1 {
	font-size: 2rem;
}
h2, .h2 {
	font-size: 1.75rem;
}
h3, .h3 {
	font-size: 1.5rem;
}
h4, .h4 {
	font-size: 1.125rem;
}
h5, .h5 {
	font-size: 1rem;
}
h6, .h6 {
	font-size: 0.85rem;
}

@media (max-width:576px) {
	h1, .h1 {
		font-size: 1.5rem;
	}
	h2, .h2 {
		font-size: 1.25rem;
	}
	h3, .h3 {
		font-size: 1rem;
	}
	h4, .h4 {
		font-size: 0.9rem;
	}
	h5, .h5 {
		font-size: 0.8rem;
	}
	h6, .h6 {
		font-size: 0.75rem;
	}
	
}
/*--------- Heighpx--------------*/
.h-10 {
	height: 10% !important;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-90 {
	height: 90px !important;
}
.h-60 {
	height: 60px !important;
}
.h-100 {
	height: 100% !important;
}
.h-150 {
	height: 150px !important;
}
.h-160 {
	height: 160px !important;
}
.h-180 {
	height: 180px !important;
}
.h-275 {
	height: 275px !important;
}
.h-365 {
	height: 365px !important;
}
.h-400 {
	height: 400px !important;
}
.h-415 {
	height: 415px !important;
}
.h-430 {
	height: 430px !important;
}
.h-450 {
	height: 450px !important;
}
.h-200 {
	height: 200px !important;
}
.h-230 {
	height: 230px !important;
}
.h-500 {
	height: 500px !important;
}
.h-550 {
	height: 550px !important;
}
.h-600 {
	height: 600px !important;
}
.h-650 {
	height: 650px !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-97 {
	height: 97px !important;
}
.h-240 {
	height: 240px !important;
}
.h-250 {
	height: 250px !important;
}
.h-260 {
	height: 260px !important;
}
.h-270 {
	height: 270px !important;
}
.h-280 {
	height: 280px !important;
}
.h-300 {
	height: 300px !important;
}
.h-340 {
	height: 340px !important;
}
.mh-100 {
	max-height: 100% !important;
}
.h-100vh{
	height: 100vh !important;
}
.hpx-1{
	height: 1px;
}
.hpx-2{
	height: 2px;
}
.hpx-3{
	height: 3px;
}
.hpx-4{
	height: 4px;
}
.hpx-5{
	height: 5px;
}
.hpx-6{
	height: 6px;
}
.hpx-7{
	height: 7px;
}
.hpx-8{
	height: 8px;
}
.hpx-9{
	height: 9px !important;
}
.hpx-10{
	height: 10px !important;
}
.hpx-20{
	height: 20px !important;
}
.hpx-25{
	height: 25px !important;
}
.hpx-30{
	height: 30px !important;
}
.hpx-35{
	height: 35px !important;
}
.hpx-40{
	height: 40px !important;
}
.hpx-45{
	height: 45px !important;
}
.hpx-50{
	height: 50px;
}
.hpx-55{
	height: 55px;
}
.hpx-60{
	height: 60px;
}
.hpx-65{
	height: 65px;
}
.hpx-70{
	height: 70px;
}
.hpx-75{
	height: 75px;
}
.hpx-80{
	height: 80px;
}
.hpx-85{
	height: 85px;
}
.hpx-90{
	height: 90px;
}
.hpx-95{
	height: 95px;
}
.hpx-100{
	height: 100px;
}
.hpx-150{
	height: 150px;
}
.hrem-6{
	height: 6rem;
}

@media (min-width: 480px) {
	.ht-xs-5 {
	  height: 5px;
	}
  
	.ht-xs-5p {
	  height: 5%;
	}
  
	.mx-ht-xs-5p {
	  max-height: 5%;
	}
  
	.mn-ht-xs-5p {
	  min-height: 5%;
	}
  
	.ht-xs-5-f {
	  height: 5px !important;
	}
  
	.ht-xs-5p-f {
	  height: 5% !important;
	}
  
	.ht-xs-10 {
	  height: 10px;
	}
  
	.ht-xs-10p {
	  height: 10%;
	}
  
	.mx-ht-xs-10p {
	  max-height: 10%;
	}
  
	.mn-ht-xs-10p {
	  min-height: 10%;
	}
  
	.ht-xs-10-f {
	  height: 10px !important;
	}
  
	.ht-xs-10p-f {
	  height: 10% !important;
	}
  
	.ht-xs-15 {
	  height: 15px;
	}
  
	.ht-xs-15p {
	  height: 15%;
	}
  
	.mx-ht-xs-15p {
	  max-height: 15%;
	}
  
	.mn-ht-xs-15p {
	  min-height: 15%;
	}
  
	.ht-xs-15-f {
	  height: 15px !important;
	}
  
	.ht-xs-15p-f {
	  height: 15% !important;
	}
  
	.ht-xs-20 {
	  height: 20px;
	}
  
	.ht-xs-20p {
	  height: 20%;
	}
  
	.mx-ht-xs-20p {
	  max-height: 20%;
	}
  
	.mn-ht-xs-20p {
	  min-height: 20%;
	}
  
	.ht-xs-20-f {
	  height: 20px !important;
	}
  
	.ht-xs-20p-f {
	  height: 20% !important;
	}
  
	.ht-xs-25 {
	  height: 25px;
	}
  
	.ht-xs-25p {
	  height: 25%;
	}
  
	.mx-ht-xs-25p {
	  max-height: 25%;
	}
  
	.mn-ht-xs-25p {
	  min-height: 25%;
	}
  
	.ht-xs-25-f {
	  height: 25px !important;
	}
  
	.ht-xs-25p-f {
	  height: 25% !important;
	}
  
	.ht-xs-30 {
	  height: 30px;
	}
  
	.ht-xs-30p {
	  height: 30%;
	}
  
	.mx-ht-xs-30p {
	  max-height: 30%;
	}
  
	.mn-ht-xs-30p {
	  min-height: 30%;
	}
  
	.ht-xs-30-f {
	  height: 30px !important;
	}
  
	.ht-xs-30p-f {
	  height: 30% !important;
	}
  
	.ht-xs-35 {
	  height: 35px;
	}
  
	.ht-xs-35p {
	  height: 35%;
	}
  
	.mx-ht-xs-35p {
	  max-height: 35%;
	}
  
	.mn-ht-xs-35p {
	  min-height: 35%;
	}
  
	.ht-xs-35-f {
	  height: 35px !important;
	}
  
	.ht-xs-35p-f {
	  height: 35% !important;
	}
  
	.ht-xs-40 {
	  height: 40px;
	}
  
	.ht-xs-40p {
	  height: 40%;
	}
  
	.mx-ht-xs-40p {
	  max-height: 40%;
	}
  
	.mn-ht-xs-40p {
	  min-height: 40%;
	}
  
	.ht-xs-40-f {
	  height: 40px !important;
	}
  
	.ht-xs-40p-f {
	  height: 40% !important;
	}
  
	.ht-xs-45 {
	  height: 45px;
	}
  
	.ht-xs-45p {
	  height: 45%;
	}
  
	.mx-ht-xs-45p {
	  max-height: 45%;
	}
  
	.mn-ht-xs-45p {
	  min-height: 45%;
	}
  
	.ht-xs-45-f {
	  height: 45px !important;
	}
  
	.ht-xs-45p-f {
	  height: 45% !important;
	}
  
	.ht-xs-50 {
	  height: 50px;
	}
  
	.ht-xs-50p {
	  height: 50%;
	}
  
	.mx-ht-xs-50p {
	  max-height: 50%;
	}
  
	.mn-ht-xs-50p {
	  min-height: 50%;
	}
  
	.ht-xs-50-f {
	  height: 50px !important;
	}
  
	.ht-xs-50p-f {
	  height: 50% !important;
	}
  
	.ht-xs-55 {
	  height: 55px;
	}
  
	.ht-xs-55p {
	  height: 55%;
	}
  
	.mx-ht-xs-55p {
	  max-height: 55%;
	}
  
	.mn-ht-xs-55p {
	  min-height: 55%;
	}
  
	.ht-xs-55-f {
	  height: 55px !important;
	}
  
	.ht-xs-55p-f {
	  height: 55% !important;
	}
  
	.ht-xs-60 {
	  height: 60px;
	}
  
	.ht-xs-60p {
	  height: 60%;
	}
  
	.mx-ht-xs-60p {
	  max-height: 60%;
	}
  
	.mn-ht-xs-60p {
	  min-height: 60%;
	}
  
	.ht-xs-60-f {
	  height: 60px !important;
	}
  
	.ht-xs-60p-f {
	  height: 60% !important;
	}
  
	.ht-xs-65 {
	  height: 65px;
	}
  
	.ht-xs-65p {
	  height: 65%;
	}
  
	.mx-ht-xs-65p {
	  max-height: 65%;
	}
  
	.mn-ht-xs-65p {
	  min-height: 65%;
	}
  
	.ht-xs-65-f {
	  height: 65px !important;
	}
  
	.ht-xs-65p-f {
	  height: 65% !important;
	}
  
	.ht-xs-70 {
	  height: 70px;
	}
  
	.ht-xs-70p {
	  height: 70%;
	}
  
	.mx-ht-xs-70p {
	  max-height: 70%;
	}
  
	.mn-ht-xs-70p {
	  min-height: 70%;
	}
  
	.ht-xs-70-f {
	  height: 70px !important;
	}
  
	.ht-xs-70p-f {
	  height: 70% !important;
	}
  
	.ht-xs-75 {
	  height: 75px;
	}
  
	.ht-xs-75p {
	  height: 75%;
	}
  
	.mx-ht-xs-75p {
	  max-height: 75%;
	}
  
	.mn-ht-xs-75p {
	  min-height: 75%;
	}
  
	.ht-xs-75-f {
	  height: 75px !important;
	}
  
	.ht-xs-75p-f {
	  height: 75% !important;
	}
  
	.ht-xs-80 {
	  height: 80px;
	}
  
	.ht-xs-80p {
	  height: 80%;
	}
  
	.mx-ht-xs-80p {
	  max-height: 80%;
	}
  
	.mn-ht-xs-80p {
	  min-height: 80%;
	}
  
	.ht-xs-80-f {
	  height: 80px !important;
	}
  
	.ht-xs-80p-f {
	  height: 80% !important;
	}
  
	.ht-xs-85 {
	  height: 85px;
	}
  
	.ht-xs-85p {
	  height: 85%;
	}
  
	.mx-ht-xs-85p {
	  max-height: 85%;
	}
  
	.mn-ht-xs-85p {
	  min-height: 85%;
	}
  
	.ht-xs-85-f {
	  height: 85px !important;
	}
  
	.ht-xs-85p-f {
	  height: 85% !important;
	}
  
	.ht-xs-90 {
	  height: 90px;
	}
  
	.ht-xs-90p {
	  height: 90%;
	}
  
	.mx-ht-xs-90p {
	  max-height: 90%;
	}
  
	.mn-ht-xs-90p {
	  min-height: 90%;
	}
  
	.ht-xs-90-f {
	  height: 90px !important;
	}
  
	.ht-xs-90p-f {
	  height: 90% !important;
	}
  
	.ht-xs-95 {
	  height: 95px;
	}
  
	.ht-xs-95p {
	  height: 95%;
	}
  
	.mx-ht-xs-95p {
	  max-height: 95%;
	}
  
	.mn-ht-xs-95p {
	  min-height: 95%;
	}
  
	.ht-xs-95-f {
	  height: 95px !important;
	}
  
	.ht-xs-95p-f {
	  height: 95% !important;
	}
  
	.ht-xs-100 {
	  height: 100px;
	}
  
	.ht-xs-100p {
	  height: 100%;
	}
  
	.mx-ht-xs-100p {
	  max-height: 100%;
	}
  
	.mn-ht-xs-100p {
	  min-height: 100%;
	}
  
	.ht-xs-100-f {
	  height: 100px !important;
	}
  
	.ht-xs-100p-f {
	  height: 100% !important;
	}
  
	.ht-xs-150 {
	  height: 150px;
	}
  
	.ht-xs-150p {
	  height: 150%;
	}
  
	.mx-ht-xs-150p {
	  max-height: 150%;
	}
  
	.mn-ht-xs-150p {
	  min-height: 150%;
	}
  
	.ht-xs-150-f {
	  height: 150px !important;
	}
  
	.ht-xs-150p-f {
	  height: 150% !important;
	}
  
	.ht-xs-200 {
	  height: 200px;
	}
  
	.ht-xs-200p {
	  height: 200%;
	}
  
	.mx-ht-xs-200p {
	  max-height: 200%;
	}
  
	.mn-ht-xs-200p {
	  min-height: 200%;
	}
  
	.ht-xs-200-f {
	  height: 200px !important;
	}
  
	.ht-xs-200p-f {
	  height: 200% !important;
	}
  
	.ht-xs-250 {
	  height: 250px;
	}
  
	.ht-xs-250p {
	  height: 250%;
	}
  
	.mx-ht-xs-250p {
	  max-height: 250%;
	}
  
	.mn-ht-xs-250p {
	  min-height: 250%;
	}
  
	.ht-xs-250-f {
	  height: 250px !important;
	}
  
	.ht-xs-250p-f {
	  height: 250% !important;
	}
  
	.ht-xs-300 {
	  height: 300px;
	}
  
	.ht-xs-300p {
	  height: 300%;
	}
  
	.mx-ht-xs-300p {
	  max-height: 300%;
	}
  
	.mn-ht-xs-300p {
	  min-height: 300%;
	}
  
	.ht-xs-300-f {
	  height: 300px !important;
	}
  
	.ht-xs-300p-f {
	  height: 300% !important;
	}
  
	.ht-xs-350 {
	  height: 350px;
	}
  
	.ht-xs-350p {
	  height: 350%;
	}
  
	.mx-ht-xs-350p {
	  max-height: 350%;
	}
  
	.mn-ht-xs-350p {
	  min-height: 350%;
	}
  
	.ht-xs-350-f {
	  height: 350px !important;
	}
  
	.ht-xs-350p-f {
	  height: 350% !important;
	}
  
	.ht-xs-400 {
	  height: 400px;
	}
  
	.ht-xs-400p {
	  height: 400%;
	}
  
	.mx-ht-xs-400p {
	  max-height: 400%;
	}
  
	.mn-ht-xs-400p {
	  min-height: 400%;
	}
  
	.ht-xs-400-f {
	  height: 400px !important;
	}
  
	.ht-xs-400p-f {
	  height: 400% !important;
	}
  
	.ht-xs-450 {
	  height: 450px;
	}
  
	.ht-xs-450p {
	  height: 450%;
	}
  
	.mx-ht-xs-450p {
	  max-height: 450%;
	}
  
	.mn-ht-xs-450p {
	  min-height: 450%;
	}
  
	.ht-xs-450-f {
	  height: 450px !important;
	}
  
	.ht-xs-450p-f {
	  height: 450% !important;
	}
  
	.ht-xs-500 {
	  height: 500px;
	}
  
	.ht-xs-500p {
	  height: 500%;
	}
  
	.mx-ht-xs-500p {
	  max-height: 500%;
	}
  
	.mn-ht-xs-500p {
	  min-height: 500%;
	}
  
	.ht-xs-500-f {
	  height: 500px !important;
	}
  
	.ht-xs-500p-f {
	  height: 500% !important;
	}
  
	.ht-xs-550 {
	  height: 550px;
	}
  
	.ht-xs-550p {
	  height: 550%;
	}
  
	.mx-ht-xs-550p {
	  max-height: 550%;
	}
  
	.mn-ht-xs-550p {
	  min-height: 550%;
	}
  
	.ht-xs-550-f {
	  height: 550px !important;
	}
  
	.ht-xs-550p-f {
	  height: 550% !important;
	}
  
	.ht-xs-600 {
	  height: 600px;
	}
  
	.ht-xs-600p {
	  height: 600%;
	}
  
	.mx-ht-xs-600p {
	  max-height: 600%;
	}
  
	.mn-ht-xs-600p {
	  min-height: 600%;
	}
  
	.ht-xs-600-f {
	  height: 600px !important;
	}
  
	.ht-xs-600p-f {
	  height: 600% !important;
	}
  
	.ht-xs-650 {
	  height: 650px;
	}
  
	.ht-xs-650p {
	  height: 650%;
	}
  
	.mx-ht-xs-650p {
	  max-height: 650%;
	}
  
	.mn-ht-xs-650p {
	  min-height: 650%;
	}
  
	.ht-xs-650-f {
	  height: 650px !important;
	}
  
	.ht-xs-650p-f {
	  height: 650% !important;
	}
  
	.ht-xs-700 {
	  height: 700px;
	}
  
	.ht-xs-700p {
	  height: 700%;
	}
  
	.mx-ht-xs-700p {
	  max-height: 700%;
	}
  
	.mn-ht-xs-700p {
	  min-height: 700%;
	}
  
	.ht-xs-700-f {
	  height: 700px !important;
	}
  
	.ht-xs-700p-f {
	  height: 700% !important;
	}
  
	.ht-xs-750 {
	  height: 750px;
	}
  
	.ht-xs-750p {
	  height: 750%;
	}
  
	.mx-ht-xs-750p {
	  max-height: 750%;
	}
  
	.mn-ht-xs-750p {
	  min-height: 750%;
	}
  
	.ht-xs-750-f {
	  height: 750px !important;
	}
  
	.ht-xs-750p-f {
	  height: 750% !important;
	}
  
	.ht-xs-800 {
	  height: 800px;
	}
  
	.ht-xs-800p {
	  height: 800%;
	}
  
	.mx-ht-xs-800p {
	  max-height: 800%;
	}
  
	.mn-ht-xs-800p {
	  min-height: 800%;
	}
  
	.ht-xs-800-f {
	  height: 800px !important;
	}
  
	.ht-xs-800p-f {
	  height: 800% !important;
	}
  
	.ht-xs-850 {
	  height: 850px;
	}
  
	.ht-xs-850p {
	  height: 850%;
	}
  
	.mx-ht-xs-850p {
	  max-height: 850%;
	}
  
	.mn-ht-xs-850p {
	  min-height: 850%;
	}
  
	.ht-xs-850-f {
	  height: 850px !important;
	}
  
	.ht-xs-850p-f {
	  height: 850% !important;
	}
  
	.ht-xs-100v {
	  height: 100vh;
	}
  }
  
  @media (min-width: 576px) {
	.ht-sm-5 {
	  height: 5px;
	}
  
	.ht-sm-5p {
	  height: 5%;
	}
  
	.mx-ht-sm-5p {
	  max-height: 5%;
	}
  
	.mn-ht-sm-5p {
	  min-height: 5%;
	}
  
	.ht-sm-5-f {
	  height: 5px !important;
	}
  
	.ht-sm-5p-f {
	  height: 5% !important;
	}
  
	.ht-sm-10 {
	  height: 10px;
	}
  
	.ht-sm-10p {
	  height: 10%;
	}
  
	.mx-ht-sm-10p {
	  max-height: 10%;
	}
  
	.mn-ht-sm-10p {
	  min-height: 10%;
	}
  
	.ht-sm-10-f {
	  height: 10px !important;
	}
  
	.ht-sm-10p-f {
	  height: 10% !important;
	}
  
	.ht-sm-15 {
	  height: 15px;
	}
  
	.ht-sm-15p {
	  height: 15%;
	}
  
	.mx-ht-sm-15p {
	  max-height: 15%;
	}
  
	.mn-ht-sm-15p {
	  min-height: 15%;
	}
  
	.ht-sm-15-f {
	  height: 15px !important;
	}
  
	.ht-sm-15p-f {
	  height: 15% !important;
	}
  
	.ht-sm-20 {
	  height: 20px;
	}
  
	.ht-sm-20p {
	  height: 20%;
	}
  
	.mx-ht-sm-20p {
	  max-height: 20%;
	}
  
	.mn-ht-sm-20p {
	  min-height: 20%;
	}
  
	.ht-sm-20-f {
	  height: 20px !important;
	}
  
	.ht-sm-20p-f {
	  height: 20% !important;
	}
  
	.ht-sm-25 {
	  height: 25px;
	}
  
	.ht-sm-25p {
	  height: 25%;
	}
  
	.mx-ht-sm-25p {
	  max-height: 25%;
	}
  
	.mn-ht-sm-25p {
	  min-height: 25%;
	}
  
	.ht-sm-25-f {
	  height: 25px !important;
	}
  
	.ht-sm-25p-f {
	  height: 25% !important;
	}
  
	.ht-sm-30 {
	  height: 30px;
	}
  
	.ht-sm-30p {
	  height: 30%;
	}
  
	.mx-ht-sm-30p {
	  max-height: 30%;
	}
  
	.mn-ht-sm-30p {
	  min-height: 30%;
	}
  
	.ht-sm-30-f {
	  height: 30px !important;
	}
  
	.ht-sm-30p-f {
	  height: 30% !important;
	}
  
	.ht-sm-35 {
	  height: 35px;
	}
  
	.ht-sm-35p {
	  height: 35%;
	}
  
	.mx-ht-sm-35p {
	  max-height: 35%;
	}
  
	.mn-ht-sm-35p {
	  min-height: 35%;
	}
  
	.ht-sm-35-f {
	  height: 35px !important;
	}
  
	.ht-sm-35p-f {
	  height: 35% !important;
	}
  
	.ht-sm-40 {
	  height: 40px;
	}
  
	.ht-sm-40p {
	  height: 40%;
	}
  
	.mx-ht-sm-40p {
	  max-height: 40%;
	}
  
	.mn-ht-sm-40p {
	  min-height: 40%;
	}
  
	.ht-sm-40-f {
	  height: 40px !important;
	}
  
	.ht-sm-40p-f {
	  height: 40% !important;
	}
  
	.ht-sm-45 {
	  height: 45px;
	}
  
	.ht-sm-45p {
	  height: 45%;
	}
  
	.mx-ht-sm-45p {
	  max-height: 45%;
	}
  
	.mn-ht-sm-45p {
	  min-height: 45%;
	}
  
	.ht-sm-45-f {
	  height: 45px !important;
	}
  
	.ht-sm-45p-f {
	  height: 45% !important;
	}
  
	.ht-sm-50 {
	  height: 50px;
	}
  
	.ht-sm-50p {
	  height: 50%;
	}
  
	.mx-ht-sm-50p {
	  max-height: 50%;
	}
  
	.mn-ht-sm-50p {
	  min-height: 50%;
	}
  
	.ht-sm-50-f {
	  height: 50px !important;
	}
  
	.ht-sm-50p-f {
	  height: 50% !important;
	}
  
	.ht-sm-55 {
	  height: 55px;
	}
  
	.ht-sm-55p {
	  height: 55%;
	}
  
	.mx-ht-sm-55p {
	  max-height: 55%;
	}
  
	.mn-ht-sm-55p {
	  min-height: 55%;
	}
  
	.ht-sm-55-f {
	  height: 55px !important;
	}
  
	.ht-sm-55p-f {
	  height: 55% !important;
	}
  
	.ht-sm-60 {
	  height: 60px;
	}
  
	.ht-sm-60p {
	  height: 60%;
	}
  
	.mx-ht-sm-60p {
	  max-height: 60%;
	}
  
	.mn-ht-sm-60p {
	  min-height: 60%;
	}
  
	.ht-sm-60-f {
	  height: 60px !important;
	}
  
	.ht-sm-60p-f {
	  height: 60% !important;
	}
  
	.ht-sm-65 {
	  height: 65px;
	}
  
	.ht-sm-65p {
	  height: 65%;
	}
  
	.mx-ht-sm-65p {
	  max-height: 65%;
	}
  
	.mn-ht-sm-65p {
	  min-height: 65%;
	}
  
	.ht-sm-65-f {
	  height: 65px !important;
	}
  
	.ht-sm-65p-f {
	  height: 65% !important;
	}
  
	.ht-sm-70 {
	  height: 70px;
	}
  
	.ht-sm-70p {
	  height: 70%;
	}
  
	.mx-ht-sm-70p {
	  max-height: 70%;
	}
  
	.mn-ht-sm-70p {
	  min-height: 70%;
	}
  
	.ht-sm-70-f {
	  height: 70px !important;
	}
  
	.ht-sm-70p-f {
	  height: 70% !important;
	}
  
	.ht-sm-75 {
	  height: 75px;
	}
  
	.ht-sm-75p {
	  height: 75%;
	}
  
	.mx-ht-sm-75p {
	  max-height: 75%;
	}
  
	.mn-ht-sm-75p {
	  min-height: 75%;
	}
  
	.ht-sm-75-f {
	  height: 75px !important;
	}
  
	.ht-sm-75p-f {
	  height: 75% !important;
	}
  
	.ht-sm-80 {
	  height: 80px;
	}
  
	.ht-sm-80p {
	  height: 80%;
	}
  
	.mx-ht-sm-80p {
	  max-height: 80%;
	}
  
	.mn-ht-sm-80p {
	  min-height: 80%;
	}
  
	.ht-sm-80-f {
	  height: 80px !important;
	}
  
	.ht-sm-80p-f {
	  height: 80% !important;
	}
  
	.ht-sm-85 {
	  height: 85px;
	}
  
	.ht-sm-85p {
	  height: 85%;
	}
  
	.mx-ht-sm-85p {
	  max-height: 85%;
	}
  
	.mn-ht-sm-85p {
	  min-height: 85%;
	}
  
	.ht-sm-85-f {
	  height: 85px !important;
	}
  
	.ht-sm-85p-f {
	  height: 85% !important;
	}
  
	.ht-sm-90 {
	  height: 90px;
	}
  
	.ht-sm-90p {
	  height: 90%;
	}
  
	.mx-ht-sm-90p {
	  max-height: 90%;
	}
  
	.mn-ht-sm-90p {
	  min-height: 90%;
	}
  
	.ht-sm-90-f {
	  height: 90px !important;
	}
  
	.ht-sm-90p-f {
	  height: 90% !important;
	}
  
	.ht-sm-95 {
	  height: 95px;
	}
  
	.ht-sm-95p {
	  height: 95%;
	}
  
	.mx-ht-sm-95p {
	  max-height: 95%;
	}
  
	.mn-ht-sm-95p {
	  min-height: 95%;
	}
  
	.ht-sm-95-f {
	  height: 95px !important;
	}
  
	.ht-sm-95p-f {
	  height: 95% !important;
	}
  
	.ht-sm-100 {
	  height: 100px;
	}
  
	.ht-sm-100p {
	  height: 100%;
	}
  
	.mx-ht-sm-100p {
	  max-height: 100%;
	}
  
	.mn-ht-sm-100p {
	  min-height: 100%;
	}
  
	.ht-sm-100-f {
	  height: 100px !important;
	}
  
	.ht-sm-100p-f {
	  height: 100% !important;
	}
  
	.ht-sm-150 {
	  height: 150px;
	}
  
	.ht-sm-150p {
	  height: 150%;
	}
  
	.mx-ht-sm-150p {
	  max-height: 150%;
	}
  
	.mn-ht-sm-150p {
	  min-height: 150%;
	}
  
	.ht-sm-150-f {
	  height: 150px !important;
	}
  
	.ht-sm-150p-f {
	  height: 150% !important;
	}
  
	.ht-sm-200 {
	  height: 200px;
	}
  
	.ht-sm-200p {
	  height: 200%;
	}
  
	.mx-ht-sm-200p {
	  max-height: 200%;
	}
  
	.mn-ht-sm-200p {
	  min-height: 200%;
	}
  
	.ht-sm-200-f {
	  height: 200px !important;
	}
  
	.ht-sm-200p-f {
	  height: 200% !important;
	}
  
	.ht-sm-250 {
	  height: 250px;
	}
  
	.ht-sm-250p {
	  height: 250%;
	}
  
	.mx-ht-sm-250p {
	  max-height: 250%;
	}
  
	.mn-ht-sm-250p {
	  min-height: 250%;
	}
  
	.ht-sm-250-f {
	  height: 250px !important;
	}
  
	.ht-sm-250p-f {
	  height: 250% !important;
	}
  
	.ht-sm-300 {
	  height: 300px;
	}
  
	.ht-sm-300p {
	  height: 300%;
	}
  
	.mx-ht-sm-300p {
	  max-height: 300%;
	}
  
	.mn-ht-sm-300p {
	  min-height: 300%;
	}
  
	.ht-sm-300-f {
	  height: 300px !important;
	}
  
	.ht-sm-300p-f {
	  height: 300% !important;
	}
  
	.ht-sm-350 {
	  height: 350px;
	}
  
	.ht-sm-350p {
	  height: 350%;
	}
  
	.mx-ht-sm-350p {
	  max-height: 350%;
	}
  
	.mn-ht-sm-350p {
	  min-height: 350%;
	}
  
	.ht-sm-350-f {
	  height: 350px !important;
	}
  
	.ht-sm-350p-f {
	  height: 350% !important;
	}
  
	.ht-sm-400 {
	  height: 400px;
	}
  
	.ht-sm-400p {
	  height: 400%;
	}
  
	.mx-ht-sm-400p {
	  max-height: 400%;
	}
  
	.mn-ht-sm-400p {
	  min-height: 400%;
	}
  
	.ht-sm-400-f {
	  height: 400px !important;
	}
  
	.ht-sm-400p-f {
	  height: 400% !important;
	}
  
	.ht-sm-450 {
	  height: 450px;
	}
  
	.ht-sm-450p {
	  height: 450%;
	}
  
	.mx-ht-sm-450p {
	  max-height: 450%;
	}
  
	.mn-ht-sm-450p {
	  min-height: 450%;
	}
  
	.ht-sm-450-f {
	  height: 450px !important;
	}
  
	.ht-sm-450p-f {
	  height: 450% !important;
	}
  
	.ht-sm-500 {
	  height: 500px;
	}
  
	.ht-sm-500p {
	  height: 500%;
	}
  
	.mx-ht-sm-500p {
	  max-height: 500%;
	}
  
	.mn-ht-sm-500p {
	  min-height: 500%;
	}
  
	.ht-sm-500-f {
	  height: 500px !important;
	}
  
	.ht-sm-500p-f {
	  height: 500% !important;
	}
  
	.ht-sm-550 {
	  height: 550px;
	}
  
	.ht-sm-550p {
	  height: 550%;
	}
  
	.mx-ht-sm-550p {
	  max-height: 550%;
	}
  
	.mn-ht-sm-550p {
	  min-height: 550%;
	}
  
	.ht-sm-550-f {
	  height: 550px !important;
	}
  
	.ht-sm-550p-f {
	  height: 550% !important;
	}
  
	.ht-sm-600 {
	  height: 600px;
	}
  
	.ht-sm-600p {
	  height: 600%;
	}
  
	.mx-ht-sm-600p {
	  max-height: 600%;
	}
  
	.mn-ht-sm-600p {
	  min-height: 600%;
	}
  
	.ht-sm-600-f {
	  height: 600px !important;
	}
  
	.ht-sm-600p-f {
	  height: 600% !important;
	}
  
	.ht-sm-650 {
	  height: 650px;
	}
  
	.ht-sm-650p {
	  height: 650%;
	}
  
	.mx-ht-sm-650p {
	  max-height: 650%;
	}
  
	.mn-ht-sm-650p {
	  min-height: 650%;
	}
  
	.ht-sm-650-f {
	  height: 650px !important;
	}
  
	.ht-sm-650p-f {
	  height: 650% !important;
	}
  
	.ht-sm-700 {
	  height: 700px;
	}
  
	.ht-sm-700p {
	  height: 700%;
	}
  
	.mx-ht-sm-700p {
	  max-height: 700%;
	}
  
	.mn-ht-sm-700p {
	  min-height: 700%;
	}
  
	.ht-sm-700-f {
	  height: 700px !important;
	}
  
	.ht-sm-700p-f {
	  height: 700% !important;
	}
  
	.ht-sm-750 {
	  height: 750px;
	}
  
	.ht-sm-750p {
	  height: 750%;
	}
  
	.mx-ht-sm-750p {
	  max-height: 750%;
	}
  
	.mn-ht-sm-750p {
	  min-height: 750%;
	}
  
	.ht-sm-750-f {
	  height: 750px !important;
	}
  
	.ht-sm-750p-f {
	  height: 750% !important;
	}
  
	.ht-sm-800 {
	  height: 800px;
	}
  
	.ht-sm-800p {
	  height: 800%;
	}
  
	.mx-ht-sm-800p {
	  max-height: 800%;
	}
  
	.mn-ht-sm-800p {
	  min-height: 800%;
	}
  
	.ht-sm-800-f {
	  height: 800px !important;
	}
  
	.ht-sm-800p-f {
	  height: 800% !important;
	}
  
	.ht-sm-850 {
	  height: 850px;
	}
  
	.ht-sm-850p {
	  height: 850%;
	}
  
	.mx-ht-sm-850p {
	  max-height: 850%;
	}
  
	.mn-ht-sm-850p {
	  min-height: 850%;
	}
  
	.ht-sm-850-f {
	  height: 850px !important;
	}
  
	.ht-sm-850p-f {
	  height: 850% !important;
	}
  
	.ht-sm-100v {
	  height: 100vh;
	}
  }
  
  @media (min-width: 768px) {
	.ht-md-5 {
	  height: 5px;
	}
  
	.ht-md-5p {
	  height: 5%;
	}
  
	.mx-ht-md-5p {
	  max-height: 5%;
	}
  
	.mn-ht-md-5p {
	  min-height: 5%;
	}
  
	.ht-md-5-f {
	  height: 5px !important;
	}
  
	.ht-md-5p-f {
	  height: 5% !important;
	}
  
	.ht-md-10 {
	  height: 10px;
	}
  
	.ht-md-10p {
	  height: 10%;
	}
  
	.mx-ht-md-10p {
	  max-height: 10%;
	}
  
	.mn-ht-md-10p {
	  min-height: 10%;
	}
  
	.ht-md-10-f {
	  height: 10px !important;
	}
  
	.ht-md-10p-f {
	  height: 10% !important;
	}
  
	.ht-md-15 {
	  height: 15px;
	}
  
	.ht-md-15p {
	  height: 15%;
	}
  
	.mx-ht-md-15p {
	  max-height: 15%;
	}
  
	.mn-ht-md-15p {
	  min-height: 15%;
	}
  
	.ht-md-15-f {
	  height: 15px !important;
	}
  
	.ht-md-15p-f {
	  height: 15% !important;
	}
  
	.ht-md-20 {
	  height: 20px;
	}
  
	.ht-md-20p {
	  height: 20%;
	}
  
	.mx-ht-md-20p {
	  max-height: 20%;
	}
  
	.mn-ht-md-20p {
	  min-height: 20%;
	}
  
	.ht-md-20-f {
	  height: 20px !important;
	}
  
	.ht-md-20p-f {
	  height: 20% !important;
	}
  
	.ht-md-25 {
	  height: 25px;
	}
  
	.ht-md-25p {
	  height: 25%;
	}
  
	.mx-ht-md-25p {
	  max-height: 25%;
	}
  
	.mn-ht-md-25p {
	  min-height: 25%;
	}
  
	.ht-md-25-f {
	  height: 25px !important;
	}
  
	.ht-md-25p-f {
	  height: 25% !important;
	}
  
	.ht-md-30 {
	  height: 30px;
	}
  
	.ht-md-30p {
	  height: 30%;
	}
  
	.mx-ht-md-30p {
	  max-height: 30%;
	}
  
	.mn-ht-md-30p {
	  min-height: 30%;
	}
  
	.ht-md-30-f {
	  height: 30px !important;
	}
  
	.ht-md-30p-f {
	  height: 30% !important;
	}
  
	.ht-md-35 {
	  height: 35px;
	}
  
	.ht-md-35p {
	  height: 35%;
	}
  
	.mx-ht-md-35p {
	  max-height: 35%;
	}
  
	.mn-ht-md-35p {
	  min-height: 35%;
	}
  
	.ht-md-35-f {
	  height: 35px !important;
	}
  
	.ht-md-35p-f {
	  height: 35% !important;
	}
  
	.ht-md-40 {
	  height: 40px;
	}
  
	.ht-md-40p {
	  height: 40%;
	}
  
	.mx-ht-md-40p {
	  max-height: 40%;
	}
  
	.mn-ht-md-40p {
	  min-height: 40%;
	}
  
	.ht-md-40-f {
	  height: 40px !important;
	}
  
	.ht-md-40p-f {
	  height: 40% !important;
	}
  
	.ht-md-45 {
	  height: 45px;
	}
  
	.ht-md-45p {
	  height: 45%;
	}
  
	.mx-ht-md-45p {
	  max-height: 45%;
	}
  
	.mn-ht-md-45p {
	  min-height: 45%;
	}
  
	.ht-md-45-f {
	  height: 45px !important;
	}
  
	.ht-md-45p-f {
	  height: 45% !important;
	}
  
	.ht-md-50 {
	  height: 50px;
	}
  
	.ht-md-50p {
	  height: 50%;
	}
  
	.mx-ht-md-50p {
	  max-height: 50%;
	}
  
	.mn-ht-md-50p {
	  min-height: 50%;
	}
  
	.ht-md-50-f {
	  height: 50px !important;
	}
  
	.ht-md-50p-f {
	  height: 50% !important;
	}
  
	.ht-md-55 {
	  height: 55px;
	}
  
	.ht-md-55p {
	  height: 55%;
	}
  
	.mx-ht-md-55p {
	  max-height: 55%;
	}
  
	.mn-ht-md-55p {
	  min-height: 55%;
	}
  
	.ht-md-55-f {
	  height: 55px !important;
	}
  
	.ht-md-55p-f {
	  height: 55% !important;
	}
  
	.ht-md-60 {
	  height: 60px;
	}
  
	.ht-md-60p {
	  height: 60%;
	}
  
	.mx-ht-md-60p {
	  max-height: 60%;
	}
  
	.mn-ht-md-60p {
	  min-height: 60%;
	}
  
	.ht-md-60-f {
	  height: 60px !important;
	}
  
	.ht-md-60p-f {
	  height: 60% !important;
	}
  
	.ht-md-65 {
	  height: 65px;
	}
  
	.ht-md-65p {
	  height: 65%;
	}
  
	.mx-ht-md-65p {
	  max-height: 65%;
	}
  
	.mn-ht-md-65p {
	  min-height: 65%;
	}
  
	.ht-md-65-f {
	  height: 65px !important;
	}
  
	.ht-md-65p-f {
	  height: 65% !important;
	}
  
	.ht-md-70 {
	  height: 70px;
	}
  
	.ht-md-70p {
	  height: 70%;
	}
  
	.mx-ht-md-70p {
	  max-height: 70%;
	}
  
	.mn-ht-md-70p {
	  min-height: 70%;
	}
  
	.ht-md-70-f {
	  height: 70px !important;
	}
  
	.ht-md-70p-f {
	  height: 70% !important;
	}
  
	.ht-md-75 {
	  height: 75px;
	}
  
	.ht-md-75p {
	  height: 75%;
	}
  
	.mx-ht-md-75p {
	  max-height: 75%;
	}
  
	.mn-ht-md-75p {
	  min-height: 75%;
	}
  
	.ht-md-75-f {
	  height: 75px !important;
	}
  
	.ht-md-75p-f {
	  height: 75% !important;
	}
  
	.ht-md-80 {
	  height: 80px;
	}
  
	.ht-md-80p {
	  height: 80%;
	}
  
	.mx-ht-md-80p {
	  max-height: 80%;
	}
  
	.mn-ht-md-80p {
	  min-height: 80%;
	}
  
	.ht-md-80-f {
	  height: 80px !important;
	}
  
	.ht-md-80p-f {
	  height: 80% !important;
	}
  
	.ht-md-85 {
	  height: 85px;
	}
  
	.ht-md-85p {
	  height: 85%;
	}
  
	.mx-ht-md-85p {
	  max-height: 85%;
	}
  
	.mn-ht-md-85p {
	  min-height: 85%;
	}
  
	.ht-md-85-f {
	  height: 85px !important;
	}
  
	.ht-md-85p-f {
	  height: 85% !important;
	}
  
	.ht-md-90 {
	  height: 90px;
	}
  
	.ht-md-90p {
	  height: 90%;
	}
  
	.mx-ht-md-90p {
	  max-height: 90%;
	}
  
	.mn-ht-md-90p {
	  min-height: 90%;
	}
  
	.ht-md-90-f {
	  height: 90px !important;
	}
  
	.ht-md-90p-f {
	  height: 90% !important;
	}
  
	.ht-md-95 {
	  height: 95px;
	}
  
	.ht-md-95p {
	  height: 95%;
	}
  
	.mx-ht-md-95p {
	  max-height: 95%;
	}
  
	.mn-ht-md-95p {
	  min-height: 95%;
	}
  
	.ht-md-95-f {
	  height: 95px !important;
	}
  
	.ht-md-95p-f {
	  height: 95% !important;
	}
  
	.ht-md-100 {
	  height: 100px;
	}
  
	.ht-md-100p {
	  height: 100%;
	}
  
	.mx-ht-md-100p {
	  max-height: 100%;
	}
  
	.mn-ht-md-100p {
	  min-height: 100%;
	}
  
	.ht-md-100-f {
	  height: 100px !important;
	}
  
	.ht-md-100p-f {
	  height: 100% !important;
	}
  
	.ht-md-150 {
	  height: 150px;
	}
  
	.ht-md-150p {
	  height: 150%;
	}
  
	.mx-ht-md-150p {
	  max-height: 150%;
	}
  
	.mn-ht-md-150p {
	  min-height: 150%;
	}
  
	.ht-md-150-f {
	  height: 150px !important;
	}
  
	.ht-md-150p-f {
	  height: 150% !important;
	}
  
	.ht-md-200 {
	  height: 200px;
	}
  
	.ht-md-200p {
	  height: 200%;
	}
  
	.mx-ht-md-200p {
	  max-height: 200%;
	}
  
	.mn-ht-md-200p {
	  min-height: 200%;
	}
  
	.ht-md-200-f {
	  height: 200px !important;
	}
  
	.ht-md-200p-f {
	  height: 200% !important;
	}
  
	.ht-md-250 {
	  height: 250px;
	}
  
	.ht-md-250p {
	  height: 250%;
	}
  
	.mx-ht-md-250p {
	  max-height: 250%;
	}
  
	.mn-ht-md-250p {
	  min-height: 250%;
	}
  
	.ht-md-250-f {
	  height: 250px !important;
	}
  
	.ht-md-250p-f {
	  height: 250% !important;
	}
  
	.ht-md-300 {
	  height: 300px;
	}
  
	.ht-md-300p {
	  height: 300%;
	}
  
	.mx-ht-md-300p {
	  max-height: 300%;
	}
  
	.mn-ht-md-300p {
	  min-height: 300%;
	}
  
	.ht-md-300-f {
	  height: 300px !important;
	}
  
	.ht-md-300p-f {
	  height: 300% !important;
	}
  
	.ht-md-350 {
	  height: 350px;
	}
  
	.ht-md-350p {
	  height: 350%;
	}
  
	.mx-ht-md-350p {
	  max-height: 350%;
	}
  
	.mn-ht-md-350p {
	  min-height: 350%;
	}
  
	.ht-md-350-f {
	  height: 350px !important;
	}
  
	.ht-md-350p-f {
	  height: 350% !important;
	}
  
	.ht-md-400 {
	  height: 400px;
	}
  
	.ht-md-400p {
	  height: 400%;
	}
  
	.mx-ht-md-400p {
	  max-height: 400%;
	}
  
	.mn-ht-md-400p {
	  min-height: 400%;
	}
  
	.ht-md-400-f {
	  height: 400px !important;
	}
  
	.ht-md-400p-f {
	  height: 400% !important;
	}
  
	.ht-md-450 {
	  height: 450px;
	}
  
	.ht-md-450p {
	  height: 450%;
	}
  
	.mx-ht-md-450p {
	  max-height: 450%;
	}
  
	.mn-ht-md-450p {
	  min-height: 450%;
	}
  
	.ht-md-450-f {
	  height: 450px !important;
	}
  
	.ht-md-450p-f {
	  height: 450% !important;
	}
  
	.ht-md-500 {
	  height: 500px;
	}
  
	.ht-md-500p {
	  height: 500%;
	}
  
	.mx-ht-md-500p {
	  max-height: 500%;
	}
  
	.mn-ht-md-500p {
	  min-height: 500%;
	}
  
	.ht-md-500-f {
	  height: 500px !important;
	}
  
	.ht-md-500p-f {
	  height: 500% !important;
	}
  
	.ht-md-550 {
	  height: 550px;
	}
  
	.ht-md-550p {
	  height: 550%;
	}
  
	.mx-ht-md-550p {
	  max-height: 550%;
	}
  
	.mn-ht-md-550p {
	  min-height: 550%;
	}
  
	.ht-md-550-f {
	  height: 550px !important;
	}
  
	.ht-md-550p-f {
	  height: 550% !important;
	}
  
	.ht-md-600 {
	  height: 600px;
	}
  
	.ht-md-600p {
	  height: 600%;
	}
  
	.mx-ht-md-600p {
	  max-height: 600%;
	}
  
	.mn-ht-md-600p {
	  min-height: 600%;
	}
  
	.ht-md-600-f {
	  height: 600px !important;
	}
  
	.ht-md-600p-f {
	  height: 600% !important;
	}
  
	.ht-md-650 {
	  height: 650px;
	}
  
	.ht-md-650p {
	  height: 650%;
	}
  
	.mx-ht-md-650p {
	  max-height: 650%;
	}
  
	.mn-ht-md-650p {
	  min-height: 650%;
	}
  
	.ht-md-650-f {
	  height: 650px !important;
	}
  
	.ht-md-650p-f {
	  height: 650% !important;
	}
  
	.ht-md-700 {
	  height: 700px;
	}
  
	.ht-md-700p {
	  height: 700%;
	}
  
	.mx-ht-md-700p {
	  max-height: 700%;
	}
  
	.mn-ht-md-700p {
	  min-height: 700%;
	}
  
	.ht-md-700-f {
	  height: 700px !important;
	}
  
	.ht-md-700p-f {
	  height: 700% !important;
	}
  
	.ht-md-750 {
	  height: 750px;
	}
  
	.ht-md-750p {
	  height: 750%;
	}
  
	.mx-ht-md-750p {
	  max-height: 750%;
	}
  
	.mn-ht-md-750p {
	  min-height: 750%;
	}
  
	.ht-md-750-f {
	  height: 750px !important;
	}
  
	.ht-md-750p-f {
	  height: 750% !important;
	}
  
	.ht-md-800 {
	  height: 800px;
	}
  
	.ht-md-800p {
	  height: 800%;
	}
  
	.mx-ht-md-800p {
	  max-height: 800%;
	}
  
	.mn-ht-md-800p {
	  min-height: 800%;
	}
  
	.ht-md-800-f {
	  height: 800px !important;
	}
  
	.ht-md-800p-f {
	  height: 800% !important;
	}
  
	.ht-md-850 {
	  height: 850px;
	}
  
	.ht-md-850p {
	  height: 850%;
	}
  
	.mx-ht-md-850p {
	  max-height: 850%;
	}
  
	.mn-ht-md-850p {
	  min-height: 850%;
	}
  
	.ht-md-850-f {
	  height: 850px !important;
	}
  
	.ht-md-850p-f {
	  height: 850% !important;
	}
  
	.ht-md-100v {
	  height: 100vh;
	}
  }
  
  @media (min-width: 992px) {
	.ht-lg-5 {
	  height: 5px;
	}
  
	.ht-lg-5p {
	  height: 5%;
	}
  
	.mx-ht-lg-5p {
	  max-height: 5%;
	}
  
	.mn-ht-lg-5p {
	  min-height: 5%;
	}
  
	.ht-lg-5-f {
	  height: 5px !important;
	}
  
	.ht-lg-5p-f {
	  height: 5% !important;
	}
  
	.ht-lg-10 {
	  height: 10px;
	}
  
	.ht-lg-10p {
	  height: 10%;
	}
  
	.mx-ht-lg-10p {
	  max-height: 10%;
	}
  
	.mn-ht-lg-10p {
	  min-height: 10%;
	}
  
	.ht-lg-10-f {
	  height: 10px !important;
	}
  
	.ht-lg-10p-f {
	  height: 10% !important;
	}
  
	.ht-lg-15 {
	  height: 15px;
	}
  
	.ht-lg-15p {
	  height: 15%;
	}
  
	.mx-ht-lg-15p {
	  max-height: 15%;
	}
  
	.mn-ht-lg-15p {
	  min-height: 15%;
	}
  
	.ht-lg-15-f {
	  height: 15px !important;
	}
  
	.ht-lg-15p-f {
	  height: 15% !important;
	}
  
	.ht-lg-20 {
	  height: 20px;
	}
  
	.ht-lg-20p {
	  height: 20%;
	}
  
	.mx-ht-lg-20p {
	  max-height: 20%;
	}
  
	.mn-ht-lg-20p {
	  min-height: 20%;
	}
  
	.ht-lg-20-f {
	  height: 20px !important;
	}
  
	.ht-lg-20p-f {
	  height: 20% !important;
	}
  
	.ht-lg-25 {
	  height: 25px;
	}
  
	.ht-lg-25p {
	  height: 25%;
	}
  
	.mx-ht-lg-25p {
	  max-height: 25%;
	}
  
	.mn-ht-lg-25p {
	  min-height: 25%;
	}
  
	.ht-lg-25-f {
	  height: 25px !important;
	}
  
	.ht-lg-25p-f {
	  height: 25% !important;
	}
  
	.ht-lg-30 {
	  height: 30px;
	}
  
	.ht-lg-30p {
	  height: 30%;
	}
  
	.mx-ht-lg-30p {
	  max-height: 30%;
	}
  
	.mn-ht-lg-30p {
	  min-height: 30%;
	}
  
	.ht-lg-30-f {
	  height: 30px !important;
	}
  
	.ht-lg-30p-f {
	  height: 30% !important;
	}
  
	.ht-lg-35 {
	  height: 35px;
	}
  
	.ht-lg-35p {
	  height: 35%;
	}
  
	.mx-ht-lg-35p {
	  max-height: 35%;
	}
  
	.mn-ht-lg-35p {
	  min-height: 35%;
	}
  
	.ht-lg-35-f {
	  height: 35px !important;
	}
  
	.ht-lg-35p-f {
	  height: 35% !important;
	}
  
	.ht-lg-40 {
	  height: 40px;
	}
  
	.ht-lg-40p {
	  height: 40%;
	}
  
	.mx-ht-lg-40p {
	  max-height: 40%;
	}
  
	.mn-ht-lg-40p {
	  min-height: 40%;
	}
  
	.ht-lg-40-f {
	  height: 40px !important;
	}
  
	.ht-lg-40p-f {
	  height: 40% !important;
	}
  
	.ht-lg-45 {
	  height: 45px;
	}
  
	.ht-lg-45p {
	  height: 45%;
	}
  
	.mx-ht-lg-45p {
	  max-height: 45%;
	}
  
	.mn-ht-lg-45p {
	  min-height: 45%;
	}
  
	.ht-lg-45-f {
	  height: 45px !important;
	}
  
	.ht-lg-45p-f {
	  height: 45% !important;
	}
  
	.ht-lg-50 {
	  height: 50px;
	}
  
	.ht-lg-50p {
	  height: 50%;
	}
  
	.mx-ht-lg-50p {
	  max-height: 50%;
	}
  
	.mn-ht-lg-50p {
	  min-height: 50%;
	}
  
	.ht-lg-50-f {
	  height: 50px !important;
	}
  
	.ht-lg-50p-f {
	  height: 50% !important;
	}
  
	.ht-lg-55 {
	  height: 55px;
	}
  
	.ht-lg-55p {
	  height: 55%;
	}
  
	.mx-ht-lg-55p {
	  max-height: 55%;
	}
  
	.mn-ht-lg-55p {
	  min-height: 55%;
	}
  
	.ht-lg-55-f {
	  height: 55px !important;
	}
  
	.ht-lg-55p-f {
	  height: 55% !important;
	}
  
	.ht-lg-60 {
	  height: 60px;
	}
  
	.ht-lg-60p {
	  height: 60%;
	}
  
	.mx-ht-lg-60p {
	  max-height: 60%;
	}
  
	.mn-ht-lg-60p {
	  min-height: 60%;
	}
  
	.ht-lg-60-f {
	  height: 60px !important;
	}
  
	.ht-lg-60p-f {
	  height: 60% !important;
	}
  
	.ht-lg-65 {
	  height: 65px;
	}
  
	.ht-lg-65p {
	  height: 65%;
	}
  
	.mx-ht-lg-65p {
	  max-height: 65%;
	}
  
	.mn-ht-lg-65p {
	  min-height: 65%;
	}
  
	.ht-lg-65-f {
	  height: 65px !important;
	}
  
	.ht-lg-65p-f {
	  height: 65% !important;
	}
  
	.ht-lg-70 {
	  height: 70px;
	}
  
	.ht-lg-70p {
	  height: 70%;
	}
  
	.mx-ht-lg-70p {
	  max-height: 70%;
	}
  
	.mn-ht-lg-70p {
	  min-height: 70%;
	}
  
	.ht-lg-70-f {
	  height: 70px !important;
	}
  
	.ht-lg-70p-f {
	  height: 70% !important;
	}
  
	.ht-lg-75 {
	  height: 75px;
	}
  
	.ht-lg-75p {
	  height: 75%;
	}
  
	.mx-ht-lg-75p {
	  max-height: 75%;
	}
  
	.mn-ht-lg-75p {
	  min-height: 75%;
	}
  
	.ht-lg-75-f {
	  height: 75px !important;
	}
  
	.ht-lg-75p-f {
	  height: 75% !important;
	}
  
	.ht-lg-80 {
	  height: 80px;
	}
  
	.ht-lg-80p {
	  height: 80%;
	}
  
	.mx-ht-lg-80p {
	  max-height: 80%;
	}
  
	.mn-ht-lg-80p {
	  min-height: 80%;
	}
  
	.ht-lg-80-f {
	  height: 80px !important;
	}
  
	.ht-lg-80p-f {
	  height: 80% !important;
	}
  
	.ht-lg-85 {
	  height: 85px;
	}
  
	.ht-lg-85p {
	  height: 85%;
	}
  
	.mx-ht-lg-85p {
	  max-height: 85%;
	}
  
	.mn-ht-lg-85p {
	  min-height: 85%;
	}
  
	.ht-lg-85-f {
	  height: 85px !important;
	}
  
	.ht-lg-85p-f {
	  height: 85% !important;
	}
  
	.ht-lg-90 {
	  height: 90px;
	}
  
	.ht-lg-90p {
	  height: 90%;
	}
  
	.mx-ht-lg-90p {
	  max-height: 90%;
	}
  
	.mn-ht-lg-90p {
	  min-height: 90%;
	}
  
	.ht-lg-90-f {
	  height: 90px !important;
	}
  
	.ht-lg-90p-f {
	  height: 90% !important;
	}
  
	.ht-lg-95 {
	  height: 95px;
	}
  
	.ht-lg-95p {
	  height: 95%;
	}
  
	.mx-ht-lg-95p {
	  max-height: 95%;
	}
  
	.mn-ht-lg-95p {
	  min-height: 95%;
	}
  
	.ht-lg-95-f {
	  height: 95px !important;
	}
  
	.ht-lg-95p-f {
	  height: 95% !important;
	}
  
	.ht-lg-100 {
	  height: 100px;
	}
  
	.ht-lg-100p {
	  height: 100%;
	}
  
	.mx-ht-lg-100p {
	  max-height: 100%;
	}
  
	.mn-ht-lg-100p {
	  min-height: 100%;
	}
  
	.ht-lg-100-f {
	  height: 100px !important;
	}
  
	.ht-lg-100p-f {
	  height: 100% !important;
	}
  
	.ht-lg-150 {
	  height: 150px;
	}
  
	.ht-lg-150p {
	  height: 150%;
	}
  
	.mx-ht-lg-150p {
	  max-height: 150%;
	}
  
	.mn-ht-lg-150p {
	  min-height: 150%;
	}
  
	.ht-lg-150-f {
	  height: 150px !important;
	}
  
	.ht-lg-150p-f {
	  height: 150% !important;
	}
  
	.ht-lg-200 {
	  height: 200px;
	}
  
	.ht-lg-200p {
	  height: 200%;
	}
  
	.mx-ht-lg-200p {
	  max-height: 200%;
	}
  
	.mn-ht-lg-200p {
	  min-height: 200%;
	}
  
	.ht-lg-200-f {
	  height: 200px !important;
	}
  
	.ht-lg-200p-f {
	  height: 200% !important;
	}
  
	.ht-lg-250 {
	  height: 250px;
	}
  
	.ht-lg-250p {
	  height: 250%;
	}
  
	.mx-ht-lg-250p {
	  max-height: 250%;
	}
  
	.mn-ht-lg-250p {
	  min-height: 250%;
	}
  
	.ht-lg-250-f {
	  height: 250px !important;
	}
  
	.ht-lg-250p-f {
	  height: 250% !important;
	}
  
	.ht-lg-300 {
	  height: 300px;
	}
  
	.ht-lg-300p {
	  height: 300%;
	}
  
	.mx-ht-lg-300p {
	  max-height: 300%;
	}
  
	.mn-ht-lg-300p {
	  min-height: 300%;
	}
  
	.ht-lg-300-f {
	  height: 300px !important;
	}
  
	.ht-lg-300p-f {
	  height: 300% !important;
	}
  
	.ht-lg-350 {
	  height: 350px;
	}
  
	.ht-lg-350p {
	  height: 350%;
	}
  
	.mx-ht-lg-350p {
	  max-height: 350%;
	}
  
	.mn-ht-lg-350p {
	  min-height: 350%;
	}
  
	.ht-lg-350-f {
	  height: 350px !important;
	}
  
	.ht-lg-350p-f {
	  height: 350% !important;
	}
  
	.ht-lg-400 {
	  height: 400px;
	}
  
	.ht-lg-400p {
	  height: 400%;
	}
  
	.mx-ht-lg-400p {
	  max-height: 400%;
	}
  
	.mn-ht-lg-400p {
	  min-height: 400%;
	}
  
	.ht-lg-400-f {
	  height: 400px !important;
	}
  
	.ht-lg-400p-f {
	  height: 400% !important;
	}
  
	.ht-lg-450 {
	  height: 450px;
	}
  
	.ht-lg-450p {
	  height: 450%;
	}
  
	.mx-ht-lg-450p {
	  max-height: 450%;
	}
  
	.mn-ht-lg-450p {
	  min-height: 450%;
	}
  
	.ht-lg-450-f {
	  height: 450px !important;
	}
  
	.ht-lg-450p-f {
	  height: 450% !important;
	}
  
	.ht-lg-500 {
	  height: 500px;
	}
  
	.ht-lg-500p {
	  height: 500%;
	}
  
	.mx-ht-lg-500p {
	  max-height: 500%;
	}
  
	.mn-ht-lg-500p {
	  min-height: 500%;
	}
  
	.ht-lg-500-f {
	  height: 500px !important;
	}
  
	.ht-lg-500p-f {
	  height: 500% !important;
	}
  
	.ht-lg-550 {
	  height: 550px;
	}
  
	.ht-lg-550p {
	  height: 550%;
	}
  
	.mx-ht-lg-550p {
	  max-height: 550%;
	}
  
	.mn-ht-lg-550p {
	  min-height: 550%;
	}
  
	.ht-lg-550-f {
	  height: 550px !important;
	}
  
	.ht-lg-550p-f {
	  height: 550% !important;
	}
  
	.ht-lg-600 {
	  height: 600px;
	}
  
	.ht-lg-600p {
	  height: 600%;
	}
  
	.mx-ht-lg-600p {
	  max-height: 600%;
	}
  
	.mn-ht-lg-600p {
	  min-height: 600%;
	}
  
	.ht-lg-600-f {
	  height: 600px !important;
	}
  
	.ht-lg-600p-f {
	  height: 600% !important;
	}
  
	.ht-lg-650 {
	  height: 650px;
	}
  
	.ht-lg-650p {
	  height: 650%;
	}
  
	.mx-ht-lg-650p {
	  max-height: 650%;
	}
  
	.mn-ht-lg-650p {
	  min-height: 650%;
	}
  
	.ht-lg-650-f {
	  height: 650px !important;
	}
  
	.ht-lg-650p-f {
	  height: 650% !important;
	}
  
	.ht-lg-700 {
	  height: 700px;
	}
  
	.ht-lg-700p {
	  height: 700%;
	}
  
	.mx-ht-lg-700p {
	  max-height: 700%;
	}
  
	.mn-ht-lg-700p {
	  min-height: 700%;
	}
  
	.ht-lg-700-f {
	  height: 700px !important;
	}
  
	.ht-lg-700p-f {
	  height: 700% !important;
	}
  
	.ht-lg-750 {
	  height: 750px;
	}
  
	.ht-lg-750p {
	  height: 750%;
	}
  
	.mx-ht-lg-750p {
	  max-height: 750%;
	}
  
	.mn-ht-lg-750p {
	  min-height: 750%;
	}
  
	.ht-lg-750-f {
	  height: 750px !important;
	}
  
	.ht-lg-750p-f {
	  height: 750% !important;
	}
  
	.ht-lg-800 {
	  height: 800px;
	}
  
	.ht-lg-800p {
	  height: 800%;
	}
  
	.mx-ht-lg-800p {
	  max-height: 800%;
	}
  
	.mn-ht-lg-800p {
	  min-height: 800%;
	}
  
	.ht-lg-800-f {
	  height: 800px !important;
	}
  
	.ht-lg-800p-f {
	  height: 800% !important;
	}
  
	.ht-lg-850 {
	  height: 850px;
	}
  
	.ht-lg-850p {
	  height: 850%;
	}
  
	.mx-ht-lg-850p {
	  max-height: 850%;
	}
  
	.mn-ht-lg-850p {
	  min-height: 850%;
	}
  
	.ht-lg-850-f {
	  height: 850px !important;
	}
  
	.ht-lg-850p-f {
	  height: 850% !important;
	}
  
	.ht-lg-100v {
	  height: 100vh;
	}
  }
  
  @media (min-width: 1200px) {
	.ht-xl-5 {
	  height: 5px;
	}
  
	.ht-xl-5p {
	  height: 5%;
	}
  
	.mx-ht-xl-5p {
	  max-height: 5%;
	}
  
	.mn-ht-xl-5p {
	  min-height: 5%;
	}
  
	.ht-xl-5-f {
	  height: 5px !important;
	}
  
	.ht-xl-5p-f {
	  height: 5% !important;
	}
  
	.ht-xl-10 {
	  height: 10px;
	}
  
	.ht-xl-10p {
	  height: 10%;
	}
  
	.mx-ht-xl-10p {
	  max-height: 10%;
	}
  
	.mn-ht-xl-10p {
	  min-height: 10%;
	}
  
	.ht-xl-10-f {
	  height: 10px !important;
	}
  
	.ht-xl-10p-f {
	  height: 10% !important;
	}
  
	.ht-xl-15 {
	  height: 15px;
	}
  
	.ht-xl-15p {
	  height: 15%;
	}
  
	.mx-ht-xl-15p {
	  max-height: 15%;
	}
  
	.mn-ht-xl-15p {
	  min-height: 15%;
	}
  
	.ht-xl-15-f {
	  height: 15px !important;
	}
  
	.ht-xl-15p-f {
	  height: 15% !important;
	}
  
	.ht-xl-20 {
	  height: 20px;
	}
  
	.ht-xl-20p {
	  height: 20%;
	}
  
	.mx-ht-xl-20p {
	  max-height: 20%;
	}
  
	.mn-ht-xl-20p {
	  min-height: 20%;
	}
  
	.ht-xl-20-f {
	  height: 20px !important;
	}
  
	.ht-xl-20p-f {
	  height: 20% !important;
	}
  
	.ht-xl-25 {
	  height: 25px;
	}
  
	.ht-xl-25p {
	  height: 25%;
	}
  
	.mx-ht-xl-25p {
	  max-height: 25%;
	}
  
	.mn-ht-xl-25p {
	  min-height: 25%;
	}
  
	.ht-xl-25-f {
	  height: 25px !important;
	}
  
	.ht-xl-25p-f {
	  height: 25% !important;
	}
  
	.ht-xl-30 {
	  height: 30px;
	}
  
	.ht-xl-30p {
	  height: 30%;
	}
  
	.mx-ht-xl-30p {
	  max-height: 30%;
	}
  
	.mn-ht-xl-30p {
	  min-height: 30%;
	}
  
	.ht-xl-30-f {
	  height: 30px !important;
	}
  
	.ht-xl-30p-f {
	  height: 30% !important;
	}
  
	.ht-xl-35 {
	  height: 35px;
	}
  
	.ht-xl-35p {
	  height: 35%;
	}
  
	.mx-ht-xl-35p {
	  max-height: 35%;
	}
  
	.mn-ht-xl-35p {
	  min-height: 35%;
	}
  
	.ht-xl-35-f {
	  height: 35px !important;
	}
  
	.ht-xl-35p-f {
	  height: 35% !important;
	}
  
	.ht-xl-40 {
	  height: 40px;
	}
  
	.ht-xl-40p {
	  height: 40%;
	}
  
	.mx-ht-xl-40p {
	  max-height: 40%;
	}
  
	.mn-ht-xl-40p {
	  min-height: 40%;
	}
  
	.ht-xl-40-f {
	  height: 40px !important;
	}
  
	.ht-xl-40p-f {
	  height: 40% !important;
	}
  
	.ht-xl-45 {
	  height: 45px;
	}
  
	.ht-xl-45p {
	  height: 45%;
	}
  
	.mx-ht-xl-45p {
	  max-height: 45%;
	}
  
	.mn-ht-xl-45p {
	  min-height: 45%;
	}
  
	.ht-xl-45-f {
	  height: 45px !important;
	}
  
	.ht-xl-45p-f {
	  height: 45% !important;
	}
  
	.ht-xl-50 {
	  height: 50px;
	}
  
	.ht-xl-50p {
	  height: 50%;
	}
  
	.mx-ht-xl-50p {
	  max-height: 50%;
	}
  
	.mn-ht-xl-50p {
	  min-height: 50%;
	}
  
	.ht-xl-50-f {
	  height: 50px !important;
	}
  
	.ht-xl-50p-f {
	  height: 50% !important;
	}
  
	.ht-xl-55 {
	  height: 55px;
	}
  
	.ht-xl-55p {
	  height: 55%;
	}
  
	.mx-ht-xl-55p {
	  max-height: 55%;
	}
  
	.mn-ht-xl-55p {
	  min-height: 55%;
	}
  
	.ht-xl-55-f {
	  height: 55px !important;
	}
  
	.ht-xl-55p-f {
	  height: 55% !important;
	}
  
	.ht-xl-60 {
	  height: 60px;
	}
  
	.ht-xl-60p {
	  height: 60%;
	}
  
	.mx-ht-xl-60p {
	  max-height: 60%;
	}
  
	.mn-ht-xl-60p {
	  min-height: 60%;
	}
  
	.ht-xl-60-f {
	  height: 60px !important;
	}
  
	.ht-xl-60p-f {
	  height: 60% !important;
	}
  
	.ht-xl-65 {
	  height: 65px;
	}
  
	.ht-xl-65p {
	  height: 65%;
	}
  
	.mx-ht-xl-65p {
	  max-height: 65%;
	}
  
	.mn-ht-xl-65p {
	  min-height: 65%;
	}
  
	.ht-xl-65-f {
	  height: 65px !important;
	}
  
	.ht-xl-65p-f {
	  height: 65% !important;
	}
  
	.ht-xl-70 {
	  height: 70px;
	}
  
	.ht-xl-70p {
	  height: 70%;
	}
  
	.mx-ht-xl-70p {
	  max-height: 70%;
	}
  
	.mn-ht-xl-70p {
	  min-height: 70%;
	}
  
	.ht-xl-70-f {
	  height: 70px !important;
	}
  
	.ht-xl-70p-f {
	  height: 70% !important;
	}
  
	.ht-xl-75 {
	  height: 75px;
	}
  
	.ht-xl-75p {
	  height: 75%;
	}
  
	.mx-ht-xl-75p {
	  max-height: 75%;
	}
  
	.mn-ht-xl-75p {
	  min-height: 75%;
	}
  
	.ht-xl-75-f {
	  height: 75px !important;
	}
  
	.ht-xl-75p-f {
	  height: 75% !important;
	}
  
	.ht-xl-80 {
	  height: 80px;
	}
  
	.ht-xl-80p {
	  height: 80%;
	}
  
	.mx-ht-xl-80p {
	  max-height: 80%;
	}
  
	.mn-ht-xl-80p {
	  min-height: 80%;
	}
  
	.ht-xl-80-f {
	  height: 80px !important;
	}
  
	.ht-xl-80p-f {
	  height: 80% !important;
	}
  
	.ht-xl-85 {
	  height: 85px;
	}
  
	.ht-xl-85p {
	  height: 85%;
	}
  
	.mx-ht-xl-85p {
	  max-height: 85%;
	}
  
	.mn-ht-xl-85p {
	  min-height: 85%;
	}
  
	.ht-xl-85-f {
	  height: 85px !important;
	}
  
	.ht-xl-85p-f {
	  height: 85% !important;
	}
  
	.ht-xl-90 {
	  height: 90px;
	}
  
	.ht-xl-90p {
	  height: 90%;
	}
  
	.mx-ht-xl-90p {
	  max-height: 90%;
	}
  
	.mn-ht-xl-90p {
	  min-height: 90%;
	}
  
	.ht-xl-90-f {
	  height: 90px !important;
	}
  
	.ht-xl-90p-f {
	  height: 90% !important;
	}
  
	.ht-xl-95 {
	  height: 95px;
	}
  
	.ht-xl-95p {
	  height: 95%;
	}
  
	.mx-ht-xl-95p {
	  max-height: 95%;
	}
  
	.mn-ht-xl-95p {
	  min-height: 95%;
	}
  
	.ht-xl-95-f {
	  height: 95px !important;
	}
  
	.ht-xl-95p-f {
	  height: 95% !important;
	}
  
	.ht-xl-100 {
	  height: 100px;
	}
  
	.ht-xl-100p {
	  height: 100%;
	}
  
	.mx-ht-xl-100p {
	  max-height: 100%;
	}
  
	.mn-ht-xl-100p {
	  min-height: 100%;
	}
  
	.ht-xl-100-f {
	  height: 100px !important;
	}
  
	.ht-xl-100p-f {
	  height: 100% !important;
	}
  
	.ht-xl-150 {
	  height: 150px;
	}
  
	.ht-xl-150p {
	  height: 150%;
	}
  
	.mx-ht-xl-150p {
	  max-height: 150%;
	}
  
	.mn-ht-xl-150p {
	  min-height: 150%;
	}
  
	.ht-xl-150-f {
	  height: 150px !important;
	}
  
	.ht-xl-150p-f {
	  height: 150% !important;
	}
  
	.ht-xl-200 {
	  height: 200px;
	}
  
	.ht-xl-200p {
	  height: 200%;
	}
  
	.mx-ht-xl-200p {
	  max-height: 200%;
	}
  
	.mn-ht-xl-200p {
	  min-height: 200%;
	}
  
	.ht-xl-200-f {
	  height: 200px !important;
	}
  
	.ht-xl-200p-f {
	  height: 200% !important;
	}
  
	.ht-xl-250 {
	  height: 250px;
	}
  
	.ht-xl-250p {
	  height: 250%;
	}
  
	.mx-ht-xl-250p {
	  max-height: 250%;
	}
  
	.mn-ht-xl-250p {
	  min-height: 250%;
	}
  
	.ht-xl-250-f {
	  height: 250px !important;
	}
  
	.ht-xl-250p-f {
	  height: 250% !important;
	}
  
	.ht-xl-300 {
	  height: 300px;
	}
  
	.ht-xl-300p {
	  height: 300%;
	}
  
	.mx-ht-xl-300p {
	  max-height: 300%;
	}
  
	.mn-ht-xl-300p {
	  min-height: 300%;
	}
  
	.ht-xl-300-f {
	  height: 300px !important;
	}
  
	.ht-xl-300p-f {
	  height: 300% !important;
	}
  
	.ht-xl-350 {
	  height: 350px;
	}
  
	.ht-xl-350p {
	  height: 350%;
	}
  
	.mx-ht-xl-350p {
	  max-height: 350%;
	}
  
	.mn-ht-xl-350p {
	  min-height: 350%;
	}
  
	.ht-xl-350-f {
	  height: 350px !important;
	}
  
	.ht-xl-350p-f {
	  height: 350% !important;
	}
  
	.ht-xl-400 {
	  height: 400px;
	}
  
	.ht-xl-400p {
	  height: 400%;
	}
  
	.mx-ht-xl-400p {
	  max-height: 400%;
	}
  
	.mn-ht-xl-400p {
	  min-height: 400%;
	}
  
	.ht-xl-400-f {
	  height: 400px !important;
	}
  
	.ht-xl-400p-f {
	  height: 400% !important;
	}
  
	.ht-xl-450 {
	  height: 450px;
	}
  
	.ht-xl-450p {
	  height: 450%;
	}
  
	.mx-ht-xl-450p {
	  max-height: 450%;
	}
  
	.mn-ht-xl-450p {
	  min-height: 450%;
	}
  
	.ht-xl-450-f {
	  height: 450px !important;
	}
  
	.ht-xl-450p-f {
	  height: 450% !important;
	}
  
	.ht-xl-500 {
	  height: 500px;
	}
  
	.ht-xl-500p {
	  height: 500%;
	}
  
	.mx-ht-xl-500p {
	  max-height: 500%;
	}
  
	.mn-ht-xl-500p {
	  min-height: 500%;
	}
  
	.ht-xl-500-f {
	  height: 500px !important;
	}
  
	.ht-xl-500p-f {
	  height: 500% !important;
	}
  
	.ht-xl-550 {
	  height: 550px;
	}
  
	.ht-xl-550p {
	  height: 550%;
	}
  
	.mx-ht-xl-550p {
	  max-height: 550%;
	}
  
	.mn-ht-xl-550p {
	  min-height: 550%;
	}
  
	.ht-xl-550-f {
	  height: 550px !important;
	}
  
	.ht-xl-550p-f {
	  height: 550% !important;
	}
  
	.ht-xl-600 {
	  height: 600px;
	}
  
	.ht-xl-600p {
	  height: 600%;
	}
  
	.mx-ht-xl-600p {
	  max-height: 600%;
	}
  
	.mn-ht-xl-600p {
	  min-height: 600%;
	}
  
	.ht-xl-600-f {
	  height: 600px !important;
	}
  
	.ht-xl-600p-f {
	  height: 600% !important;
	}
  
	.ht-xl-650 {
	  height: 650px;
	}
  
	.ht-xl-650p {
	  height: 650%;
	}
  
	.mx-ht-xl-650p {
	  max-height: 650%;
	}
  
	.mn-ht-xl-650p {
	  min-height: 650%;
	}
  
	.ht-xl-650-f {
	  height: 650px !important;
	}
  
	.ht-xl-650p-f {
	  height: 650% !important;
	}
  
	.ht-xl-700 {
	  height: 700px;
	}
  
	.ht-xl-700p {
	  height: 700%;
	}
  
	.mx-ht-xl-700p {
	  max-height: 700%;
	}
  
	.mn-ht-xl-700p {
	  min-height: 700%;
	}
  
	.ht-xl-700-f {
	  height: 700px !important;
	}
  
	.ht-xl-700p-f {
	  height: 700% !important;
	}
  
	.ht-xl-750 {
	  height: 750px;
	}
  
	.ht-xl-750p {
	  height: 750%;
	}
  
	.mx-ht-xl-750p {
	  max-height: 750%;
	}
  
	.mn-ht-xl-750p {
	  min-height: 750%;
	}
  
	.ht-xl-750-f {
	  height: 750px !important;
	}
  
	.ht-xl-750p-f {
	  height: 750% !important;
	}
  
	.ht-xl-800 {
	  height: 800px;
	}
  
	.ht-xl-800p {
	  height: 800%;
	}
  
	.mx-ht-xl-800p {
	  max-height: 800%;
	}
  
	.mn-ht-xl-800p {
	  min-height: 800%;
	}
  
	.ht-xl-800-f {
	  height: 800px !important;
	}
  
	.ht-xl-800p-f {
	  height: 800% !important;
	}
  
	.ht-xl-850 {
	  height: 850px;
	}
  
	.ht-xl-850p {
	  height: 850%;
	}
  
	.mx-ht-xl-850p {
	  max-height: 850%;
	}
  
	.mn-ht-xl-850p {
	  min-height: 850%;
	}
  
	.ht-xl-850-f {
	  height: 850px !important;
	}
  
	.ht-xl-850p-f {
	  height: 850% !important;
	}
  
	.ht-xl-100v {
	  height: 100vh;
	}
  }

  
  .ht-100v {
	height: 100vh;
  }
  .ht-auto {
	height: auto;
  }
	