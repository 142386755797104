.fc .fc-button-primary {
	color: #fff;
	background-color: $primary !important;
	border-color: $primary !important;
	box-shadow: 0 5px 10px rgba(108, 95, 252, .18);
	
}
.fc .fc-button-primary:hover {
	color: #fff;
	background-color: $primary-hover;
	border-color: $primary-border;
}
.fc .fc-button-primary:disabled {
	/* not DRY */
	color: #fff;
	background-color: $primary ;
	border-color: $primary ;
}
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
	color: #fff;
	background-color:  $primary;
	border-color:  $primary;
	opacity: 0.5;
}
.fc-daygrid-event.fc-event{
	background: $primary;
	color: #fff !important;
}
.fc .fc-list-event-dot {
	display: inline-block;
	box-sizing: content-box;
	width: 0;
	height: 0;
	border: 5px solid $primary ;
	border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, $primary );
	border-radius: 5px;
	border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}
.fc-v-event {
	/* allowed to be top-level */
	display: block;
	border: 1px solid $primary ;
	border: 1px solid var(--fc-event-border-color, $primary );
	background-color: $primary ;
	background-color: var(--fc-event-bg-color, $primary )
}
.fc-daygrid-event-dot {
	/* the actual dot */
	display: none;
	margin: 4px;
	box-sizing: content-box;
	width: 0;
	height: 0;
	border: 4px solid $primary ;
	border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, $primary );
	border-radius: 4px;
	border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}
.fc-h-event {
	/* allowed to be top-level */
	display: block;
	border: 1px solid $primary ;
	border: 1px solid $primary ;
	background-color: $primary ;
	background-color: $primary 
}
@media (max-width:576px) {
	.fc-col-header-cell-cushion {
		font-size: 10px;
	}
}	