/*!
 * Datetimepicker for Amaze UI
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Improvements by Sébastien Malot
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 */

 .datetimepicker {
	background-color: #ffffff;
	border: 1px solid #e8e8f7;
	font-family: inherit;
	font-size: inherit;
	padding: 15px;
	margin: 0 0 0;
	display: none;
	width: auto !important;
	z-index: 5 !important;
  
	&:before {
	  content: "";
	  display: none;
	  width: 0;
	  height: 0;
	  vertical-align: middle;
	  border-bottom: 7px solid #3bb4f2;
	  border-right: 7px solid transparent;
	  border-left: 7px solid transparent;
	  border-top: 0 dotted;
	  -webkit-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  transform: rotate(360deg);
	  position: absolute;
	}
  
	&.datetimepicker-rtl {
	  direction: rtl;
  
	  table tr td span {
		float: right;
	  }
	}
  
	> div {
	  display: none;
	}
  
	table {
	  width: 238px;
	  background: #3bb4f2;
	  border-radius: 2px;
  
	  th {
		-webkit-transition: background-color 300ms ease-out;
		transition: background-color 300ms ease-out;
  
		&.next, &.prev {
		  -webkit-transition: background-color 300ms ease-out;
		  transition: background-color 300ms ease-out;
		}
  
		width: 34px;
		height: 40px;
		text-align: center;
		font-weight: 400;
  
		&.switch {
		  color: #3b4863;
		  font-weight: 700;
		  font-size: 13px;
		}
  
		&.next:hover, &.prev:hover, &.switch:hover {
		  color: #3b4863;
		}
  
		&.next, &.prev {
		  color: #3b4863;
		  cursor: pointer;
		}
  
		&.next i, &.prev i {
		  width: 34px;
		  height: 34px;
		  display: inline-block;
		}
  
		&.next {
		  border-top-right-radius: 2px;
		}
  
		&.prev {
		  border-top-left-radius: 2px;
		}
  
		&.dow {
		  color: #100f15;
		  background: #fff;
		}
  
		&.today {
		  background: #fff;
		  color: #3bb4f2;
		  cursor: pointer;
  
		  &:hover {
			background: #f0f0f0;
		  }
		}
	  }
	}
  }
  
  .datetimepicker-days td:hover {
	background: #f0f0f0;
  }
  
  .datetimepicker-hours span {
	&.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
	  background: #f0f0f0;
	}
  }
  
  .datetimepicker-minutes span {
	&.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
	  background: #f0f0f0;
	}
  }
  
  .datetimepicker-months span {
	&.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
	  background: #f0f0f0;
	}
  }
  
  .datetimepicker-years span {
	&.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
	  background: #f0f0f0;
	}
  }
  
  .datetimepicker table {
	.icon-arrow-left:before {
	  display: inline-block;
	  font: 1.6rem/1 "FontAwesome", sans-serif;
	  font-size: inherit;
	  text-rendering: auto;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  -webkit-transform: translate(0, 0);
	  -ms-transform: translate(0, 0);
	  transform: translate(0, 0);
	  content: "\f053";
	  vertical-align: bottom;
	}
  
	.icon-arrow-right:before {
	  display: inline-block;
	  font: 1.6rem/1 "FontAwesome", sans-serif;
	  font-size: inherit;
	  text-rendering: auto;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  -webkit-transform: translate(0, 0);
	  -ms-transform: translate(0, 0);
	  transform: translate(0, 0);
	  content: "\f053";
	  vertical-align: bottom;
	  content: "\f054";
	}
  
	td {
	  padding: 5px;
	  width: 34px;
	  height: 34px;
	  cursor: pointer;
	  font-weight: 500;
	  font-size: 12px;
	  color: #313b54;
	  background: #fff;
	  text-align: center;
  
	  &.today {
		background: rgba(16, 160, 234, 0.5);
		color: #fff;
	  }
	}
  
	span.active, td.active {
	  background-color: #77bc21;
	  color: #fff;
	}
  
	span.active:hover, td.active:hover {
	  background-color: #77bc21;
	}
  
	.new, .old {
	  color: #5fa10b94;
	}
  
	.disabled {
	  background: #fafafa;
	  color: #999;
	  cursor: not-allowed;
	}
  }
  
  .datetimepicker-hours table td, .datetimepicker-minutes table td, .datetimepicker-months table td, .datetimepicker-years table td {
	height: auto;
	background: #fff;
  }
  
  .datetimepicker-hours table td {
	&:after, &:before {
	  content: " ";
	  display: table;
	}
  }
  
  .datetimepicker-minutes table td {
	&:after, &:before {
	  content: " ";
	  display: table;
	}
  }
  
  .datetimepicker-months table td {
	&:after, &:before {
	  content: " ";
	  display: table;
	}
  }
  
  .datetimepicker-years table td {
	&:after, &:before {
	  content: " ";
	  display: table;
	}
  }
  
  .datetimepicker-hours table td:after, .datetimepicker-minutes table td:after, .datetimepicker-months table td:after, .datetimepicker-years table td:after {
	clear: both;
  }
  
  .datetimepicker-hours span {
	&.hour, &.minute, &.month, &.year {
	  float: left;
	  height: 38px;
	  width: 74.5px;
	  line-height: 38px;
	  text-align: center;
	}
  }
  
  .datetimepicker-minutes span {
	&.hour, &.minute, &.month, &.year {
	  float: left;
	  height: 38px;
	  width: 74.5px;
	  line-height: 38px;
	  text-align: center;
	}
  }
  
  .datetimepicker-months span {
	&.hour, &.minute, &.month, &.year {
	  float: left;
	  height: 38px;
	  width: 74.5px;
	  line-height: 38px;
	  text-align: center;
	}
  }
  
  .datetimepicker-years span {
	&.hour, &.minute, &.month, &.year {
	  float: left;
	  height: 38px;
	  width: 74.5px;
	  line-height: 38px;
	  text-align: center;
	}
  }
  
  .datetimepicker-hours {
	fieldset, legend {
	  margin: 0;
	  padding: 0;
	}
  }
  
  .datetimepicker-minutes {
	fieldset, legend {
	  margin: 0;
	  padding: 0;
	}
  }
  
  .datetimepicker-inline {
	width: 238px;
  
	&:before {
	  content: none;
	}
  }
  
  .datetimepicker-dropdown-bottom-right:before {
	top: -7px;
	left: 10px;
  }
  
  .datetimepicker-dropdown-bottom-left:before {
	top: -7px;
	right: 10px;
  }
  
  .datetimepicker-dropdown-top-left, .datetimepicker-dropdown-top-right {
	margin-top: 0;
  }
  
  .datetimepicker-dropdown-top-left:before, .datetimepicker-dropdown-top-right:before {
	display: inline-block;
	width: 0;
	height: 0;
	vertical-align: middle;
	border-top: 7px solid #fff;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 0 dotted;
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
	bottom: -7px;
	right: 10px;
  }
  
  .am-input-group.date span.add-on {
	cursor: pointer;
  }
  
  .dropdown-menu {
	position: absolute;
	display: none;
  }
  
  @media only screen and (max-width: 640px) {
	.datetimepicker {
	  width: 100%;
	  left: 0 !important;
  
	  table {
		width: 100%;
	  }
  
	  td span {
		&.hour, &.minute, &.month, &.year {
		  width: 25%;
		}
	  }
  
	  &:before {
		content: none;
	  }
	}
  
	.next, .prev {
	  width: 44px;
	  height: 44px;
	}
  }
  
  .ui-datepicker .ui-datepicker-calendar td a {
	transition: all 0.2s ease-in-out;
	padding: 5px;
	color: #313b54;
	padding: 10px 12px;
	display: block;
	font-weight: 500;
	font-size: 12px;
	border: 0;
	border-radius: 11px;
	height: 38px;
	width: 38px;
  }