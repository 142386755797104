.ribbon1 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: $white;
	z-index:1;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 24px solid transparent;
		border-top: 13px solid #45aaf2;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #45aaf2;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-top-right-radius: 8px;
		&:before,
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			height: 6px;
			width: 6px;
			left: -6px;
			top: 0;
			background: #45aaf2;
		}
		&:after {
			height: 6px;
			width: 8px;
			left: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #45aaf2;
		}
	}
}

.ribbon {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
	span {
		font-size: 10px;
		font-weight: bold;
		color: #FFF;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		width: 100px;
		display: block;
		background: #79A70A;
		background: linear-gradient(#45aaf2 0%, #45aaf2 100%);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		right: -21px;
		&::before {
			content: "";
			position: absolute;
			left: 0px;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #45aaf2;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #45aaf2;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0px;
			top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-right: 3px solid #45aaf2;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #45aaf2;
		}
	}
}