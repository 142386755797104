.select2-container--default {
	.select2-selection--single {
		background-color: #f6f7fc;
		border: 1px solid #eaedf1 !important;
	}
	&.select2-container--focus .select2-selection--multiple {
		background-color: $white;
		border: 1px solid #9ca3b1;
		outline: 0;
		box-shadow: none;
	}
	.select2-selection--multiple {
		background-color: #f6f7fc;
		border: 1px solid #eaedf1 !important;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid #eaedf1 !important;
	}
	.select2-selection--multiple {
		.select2-selection__choice,
		.select2-selection__choice__remove {
			color: $white !important;
		}
	}
	.select2-results>.select2-results__options {
		box-shadow: 0px 16px 18px rgba(6, 10, 48, .1);
	}
}

.select2-container .select2-selection--single {
	height: auto !important;
	text-align: left;
}

.select2-container--default .select2-selection--single {
	.select2-selection__rendered {
		color: #444;
		line-height: 32px !important;
	}
	.select2-selection__arrow {
		height: 2rem !important;
	}
}
