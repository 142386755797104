/*
 * bootstrap-tagsinput v0.8.0
 * 
 */

.bootstrap-tagsinput {
  background-color: #fff;
  display: inline-block;
  color: #777ab2;
  vertical-align: middle;
  border-radius: 7px;
  width: 100%;
  cursor: text;
  overflow: auto;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: 300px;
  }

  &.form-control input {
    &::-moz-placeholder {
      color: #777;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #777;
    }
  }

  input:focus {
    border: none;
    box-shadow: none;
  }

  .badge {
    color: #fff;
    padding: 4px 8px !important;
    border-radius: 5px;
    font-size: 0.85rem !important;
    line-height: 1.1;
    margin: 6px 4px;
    max-width: 300px;
    white-space: normal;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;

      &:after {
        content: "×";
        padding: 3px 8px;
        border-radius: 50%;
        font-size: 16px;
        display: inline-block;
        text-align: center;
        outline: none;
      }

      &:hover {
        &:after {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
