/* ###### Chat  ###### */

@media (max-width: 991.98px) {
  .main-content-body-show {
      .main-header-menu-icon {
          display: none;
      }
      .main-header-left .main-header-arrow {
          display: block;
      }
      .main-content-left-chat {
          display: none;
      }
      .main-content-body-chat {
          display: flex;
      }
  }
}

.main-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-nav-line-chat {
  border-bottom: 1px solid $border;
  flex-shrink: 0;
  flex-direction: row;
  padding-left: 20px;
  padding: 0;
  .nav-link {
      font-size: 13px;
      font-weight: 700;
      color: $dark;
      text-transform: uppercase;
      padding: 0.8rem 1.3rem;
      border-bottom: 3px solid transparent;
      &:hover,
      &:focus,
      &:active {
          outline: none;
      }
      +.nav-link {
          margin-top: 0;
          margin-left: 18px;
      }
  }
}

.main-chat-contacts-wrapper {
  padding: 10px 20px;
  flex-shrink: 0;
  overflow: hidden;
  .main-content-label,
  .card-table-two .card-title {
      margin-bottom: 15px;
      line-height: 1;
  }
}

@media (min-width: 992px) {
  .main-chat-contacts-wrapper {
      padding: 10px 15px 10px 0;
  }
}

.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .main-chat-contacts-wrapper .card-title {
  margin-bottom: 15px;
  line-height: 1;
}

.main-chat-contacts-wrapper {
  .lSSlideOuter,
  .lSSlideWrapper {
      overflow: visible;
  }
}

.main-chat-contacts {
  padding-left: 20px;
  display: flex;
  .lslide {
      display: flex;
      flex-direction: column;
      align-items: center;
      float: none;
      margin-right: 10px;
  }
  small {
      font-size: 11px;
      width: 36px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 3px;
      text-align: center;
  }
}

.main-chat-contacts-more {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 500;
  color: $white;
}

@media (min-width: 992px) {
  .main-chat-list {
      height: calc(100% - 134px);
      position: relative;
  }
}

.main-chat-list {
  .media {
      padding: .75rem 1.5rem;
      border: 1px solid $border;
      position: relative;
      border-left: 0;
      border-right: 0;
      .main-img-user {
          flex-shrink: 0;
          top: 3px;
      }
      +.media {
          margin-top: -1px;
          border-top: 1px solid $border;
      }
      &.new {
          background-color: $white;
          .main-img-user span {
              display: flex;
          }
          .media-contact-name span:first-child {
              font-weight: 500;
              color: #404c6d;
          }
          .media-body p {
              color: #8f9cc0;
          }
      }
      &:hover {
          cursor: pointer;
          background-color: $primary-02;
          border-top-color: $border;
          border-bottom-color: $border;
      }
      &:hover:first-child,
      &:focus:first-child {
          border-top-color: transparent;
      }
      &.selected {
          background-color: $primary-02;
          border-top-color: $background;
          border-bottom-color: $background;
          &:first-child {
              border-top-color: transparent;
          }
          &::after {
              display: block;
          }
          .media-contact-name span:first-child {
              color: #404c6d;
          }
          .media-body p {
              color: #8f9cc0;
          }
      }
  }
  .main-img-user span {
      position: absolute;
      top: 0;
      left: -2px;
      width: 14px;
      height: 14px;
      line-height: 14px;
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 9px;
      font-weight: 500;
      color: $white;
      background-color: $secondary;
      box-shadow: 0 0 0 2px $white;
  }
  .media-body {
      margin-left: 15px;
      p {
          font-size: 13px;
          margin-bottom: 0;
          color: #76839a;
          line-height: 1.35;
      }
  }
  .media-contact-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2px;
      span {
          &:first-child {
              display: block;
              font-size: 14px;
              font-weight: 500;
              color: #404c6d;
          }
          &:last-child {
              display: block;
              font-size: 11px;
              color: #76839a;
          }
      }
  }
}

@media (min-width: 992px) {
  .main-chat-list .media {
      padding: .75rem 1.5rem;
  }
}

.main-content-body-chat {
  flex-direction: column;
}

@media (min-width: 992px) {
  .main-content-body-chat {
      display: flex;
  }
}

.main-chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 12px;
  border-bottom: 1px solid $border;
  .nav {
      margin-left: auto;
      align-items: center;
  }
  .nav-link {
      padding: 0;
      color: #76839a;
      font-size: 16px;
      text-align: center;
      padding: .35rem .75rem;
      margin: 0 2px;
      i {
          line-height: 0;
      }
      .typcn {
          line-height: .9;
          &::before {
              width: auto;
          }
      }
      +.nav-link {
          margin-left: 15px;
      }
  }
}

@media (min-width: 576px) {
  .main-chat-header .nav-link {
      display: block;
  }
}

.main-chat-msg-name {
  margin-left: 15px;
  h6 {
      margin-bottom: 0px;
      font-size: 15px;
      font-weight: 700;
  }
  small {
      font-size: 12px;
      color: #76839a;
  }
}

@media (min-width: 992px) {
  .main-chat-body {
      padding-bottom: 0;
      height: 700px;
      overflow: hidden;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media.flex-row-reverse .media-body {
      margin-left: 55px;
  }
}

.main-chat-body .media-body {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  >div:last-child {
      font-size: 11px;
      color: #76839a;
      display: flex;
      align-items: center;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media-body {
      margin-right: 55px;
  }
}

.main-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;
  &:first-of-type {
      margin-top: 0;
  }
  span {
      position: relative;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      background: #fff;
      padding: 25px;
      border-radius: 4px;
  }
}

.main-chat-time::before {
  content: "";
  position: absolute;
  width: 40% !important;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #e9e9ef;
  top: 10px;
}

@media (max-width: 991px) {
  .main-chat-time::before {
    left: 0 !important;
    right: 0 !important;
  }
}

.main-chat-time::after {
  content: "";
  position: absolute;
  width: 40% !important;
  height: 1px;
  left: auto;
  right: 0;
  background-color: #e9e9ef;
  top: 10px;
}

.main-chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 60px;
  padding-right: 20px;
  border-top: 1px solid $border;
  background-color: $white;
  position: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 7px 7px;
  .nav {
      align-items: center;
  }
  .nav-link {
      padding: 0;
      color: #76839a;
      font-size: 16px;
      display: none;
      +.nav-link {
          // margin-left: 10px;
      }
  }
  .form-control {
      flex: 1;
      margin: 0 10px;
      padding-left: 12px;
      padding-right: 0;
      border-width: 0;
      border: 1px solid $border;
      &:hover,
      &:focus {
          box-shadow: none;
      }
  }
}

@media (min-width: 992px) {
  .main-chat-footer {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .nav-link {
      display: block;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .form-control {
      margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .main-chat-footer .form-control {
      margin: 0 20px;
  }
}

.main-msg-send {
  font-size: 20px;
}

.main-content-title {
  color: #170c6b;
  font-weight: 500;
  font-size: 32px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
}

.main-content-left-contacts {
  .main-content-breadcrumb,
  .main-content-title {
      padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .main-content-left-contacts {
      .main-content-breadcrumb,
      .main-content-title {
          padding-left: 0;
      }
  }
  .main-content-body-contacts {
      display: block;
      overflow-y: auto;
  }
}

.main-chat-list .media.new .main-img-user span {
  display: flex;
}

.media img {
  border-radius: 4px;
}

.main-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.main-chat-list .media .main-img-user {
  flex-shrink: 0;
  top: 3px;
}

.main-img-user {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

@media (min-width: 992px) {
  .main-chat-list {
      height: 700px;
      position: relative;
  }
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}

.far {
  font-weight: 400;
}

.main-msg-wrapper {
  padding: 10px 15px;
  background-color: #f9faf8;
  font-size: 13px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
}

.main-chat-body {
  position: relative;
  .content-inner {
      padding: 20px;
  }
  .media {
      +.media {
          margin-top: 20px;
      }
      &.flex-row-reverse {
          .media-body {
              margin-left: 0;
              margin-right: 20px;
              align-items: flex-end;
          }
          .main-msg-wrapper {
              background-color: $primary-02;
              color: $black;
              position: relative;
              border-top-right-radius: 0;
              border-bottom-left-radius: 0;
          }
      }
  }
}


/* ###### Chat  ###### */

.chat-left {
  .main-msg-wrapper {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
  }
}
.profile-user-online {
  background-repeat: no-repeat;
}

.slick-slider {
  position: relative;
  display: block;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-slider .slick-list {
  margin: 0 -6px;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}

.slick-slider .slick-list .slick-slide {
  padding: 0 6px;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
}

.recent-box .recent-profile {
  height: 90px;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.chat-profile {
  line-height: 1;
  padding: 0.75rem;
  color: $default-color;
  i {
      font-size: 14px;
  }
  &:hover{
    color: $primary !important;
  }
}

.chat-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.shared-files {
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid $border;
}

@media (min-width: 992px) {
  .main-content-app {
      height: calc(100vh - 250px);
  }
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.media-files{
  padding: 0.75rem;
}

.dropdown-item{
  // padding: 0.5rem 1.5rem !important;

  &:focus, &.active{
    background-color: #ffffff;
    color: $primary !important;
  }
}

.tabs-menu{
  .panel-tabs{
    li a{
      color: $default-color !important;
      &.active{
        color: $primary !important;
      }
    }
  }
}
@media (max-width:1279.98px) {
  .main-chat-footer {
    padding-bottom: 1.5rem;
  }
}
.main-chat-footer {
  .nav-link.active, .nav-link:hover, .nav-link:focus {
    color: $primary !important;
  }
}