/* PrismJS 1.17.1
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

.token {
  color: #d20808;

  &.comment,
  &.prolog,
  &.doctype,
  &.cdata {
    color: #4cb350;
  }

  &.punctuation {
    color: #b1abab;
  }
}

.namespace {
  opacity: 0.7;
}

.token {
  &.property,
  &.tag,
  &.boolean,
  &.number,
  &.constant,
  &.symbol,
  &.deleted {
    color: #905;
  }

  &.selector,
  &.attr-name,
  &.string,
  &.char,
  &.builtin,
  &.inserted {
    color: #ffc107;
  }

  &.operator,
  &.entity,
  &.url {
    color: #9a6e3a;
    background: hsla(0, 0%, 100%, 0.1);
  }
}

.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.1);
}

.token {
  &.atrule,
  &.attr-value,
  &.keyword {
    color: #de20af;
  }

  &.function,
  &.class-name {
    color: #dd4a68;
  }

  &.regex,
  &.important,
  &.variable {
    color: #e90;
  }

  &.important,
  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.entity {
    cursor: help;
  }
}
