/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: $default-color;
	background-color: rgba($gray-light, 0.55);
	border-radius: 5px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.tag-outline-primary{
		border-color: $primary !important;
		color: $primary;
		i{
			color: $primary;
		}
	}
	&.tag-outline-secondary{
		border-color: $secondary !important;
		color: $secondary;
		i{
			color: $secondary;
		}
	}
	&.tag-outline-success{
		border-color: $success !important;
		color: $success;
		i{
			color: $success;
		}
	}
	&.tag-outline-info{
		border-color: $info !important;
		color: $info;
		i{
			color: $info;
		}
	}
	&.tag-outline-warning{
		border-color: $warning !important;
		color: $warning;
		i{
			color: $warning;
		}
	}
	&.tag-outline-danger{
		border-color: $danger !important;
		color: $danger;
		i{
			color: $danger;
		}
	}
	&.tag-outline-light{
		border-color: $gray-light !important;
		color: $gray-light;
		i{
			color: $gray-light;
		}
	}
	&.tag-outline-dark{
		border-color: $dark !important;
		color: $dark;
		i{
			color: $dark;
		}
	}
}
a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba($gray-light, 0.65);
		color: inherit;
	}
}
.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background-color: rgba($gray-light, 0.65);
	margin: 0 -.5rem 0 .5rem;
	text-align: center;
	min-width: 1.5rem;
	&:last-child {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}
a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba($gray-light, 0.7);
		color: inherit;
	}
}
.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 3px 0 0 3px;
	margin: 0 .5rem 0 -.5rem;
}
.tag-blue {
	background-color: $blue !important;
	color: $white;
}
.tag-indigo {
	background-color: $indigo !important;
	color: $white;
}
.tag-purple {
	background-color: $purple !important;
	color: $white;
}
.tag-pink {
	background-color: $pink !important;
	color: $white;
}
.tag-red {
	background-color: $red !important;
	color: $white;
}
.tag-orange {
	background-color: $orange !important;
	color: $white;
}
.tag-yellow {
	background-color: $yellow !important;
	color: $white;
}
.tag-green {
	background-color: $green !important;
	color: $white;
}
.tag-teal {
	background-color: $teal !important;
	color: $white;
}
.tag-cyan {
	background-color: $cyan !important;
	color: $white;
}
.tag-white {
	background-color: $white !important;
	color: $white;
}
.tag-gray {
	background-color: $gray !important;
	color: $white;
}
.tag-gray-dark {
	background-color: $dark !important;
	color: $white;
}
.tag-azure {
	background-color: $azure !important;
	color: $white;
}
.tag-lime {
	background-color: $lime !important;
	color: $white;
}
.tag-primary {
	background-color: $primary !important;
	color: $white;
}
.tag-secondary {
	background-color: $secondary !important;
	color: $white;
}
.tag-success {
	background-color: $green !important;
	color: $white;
}
.tag-info {
	background-color: $azure !important;
	color: $white;
}
.tag-warning {
	background-color: $warning !important;
	color: $white;
}
.tag-danger {
	background-color: $danger !important;
	color: $white;
}
.tag-light {
	background-color: $gray-light !important;
	color: $gray-dark;
}
.tag-dark {
	background-color: $dark !important;
	color: $white;
}
.tag-rounded {
	border-radius: 50px;
	.tag-avatar {
		border-radius: 50px;
	}
}
.tags {
	margin-bottom: -.5rem;
	font-size: 0;
	>.tag {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}
.badge{
	&.bg-primary{
		color: $white;
		background-color: $primary;
	}
	&.bg-secondary{
		color: $white;
		background-color: $secondary;
	}
	&.bg-success{
		color: $white;
		background-color: $success;
	}
	&.bg-info{
		color: $white;
		background-color: $info;
	}
	&.bg-warning{
		color: $white;
		background-color: $warning;
	}
	&.bg-danger{
		color: $white;
		background-color: $danger;
	}
	&.bg-light{
		color: $gray-dark !important;
		background-color: $gray-light !important;
	}
	&.bg-dark{
		color: $white;
		background-color: $dark;
	}
}
