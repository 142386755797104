/*----- Avatars -----*/

.country-selector .avatar {
	width: 1.4rem;
	height: 1.2rem;
}
.avatar {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	display: inline-block;
	position: relative;
	text-align: center;
	color: $white;
	font-weight: 500;
	vertical-align: bottom;
	font-size: .875rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	i {
		font-size: 125%;
		vertical-align: sub;
	}
}
.avatar-status {
	position: absolute;
	right: -2px;
	bottom: -2px;
	width: .75rem;
	height: .75rem;
	border: 2px solid $white;
	border-radius: 50%;
}
.avatar-sm {
	width: 1.6rem;
	height: 1.6rem;
	line-height: 1.6rem;
	font-size: .75rem;
}
.avatar-md {
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	font-size: 1rem;
}
.avatar-lg {
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	font-size: 1.25rem;
}
.avatar-xl {
	width: 4rem;
	height: 4rem;
	line-height: 4rem;
	font-size: 1.75rem;
}
.avatar-xxl {
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
}
.avatar-placeholder {
	background: #ced4da url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="#868e96" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>') no-repeat (center / 80%);
}
.avatar-list {
	margin: 0 0 -.5rem;
	padding: 0;
	font-size: 0;
	.avatar {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}
.avatar-list-stacked .avatar {
	margin-right: -.8em !important;
	box-shadow: 0 0 0 2px $white;
}
.avatar-blue {
	background-color: #c8d9f1;
	color: #467fcf;
}
.avatar-indigo {
	background-color: #d1d5f0;
	color: $indigo;
}
.avatar-purple {
	background-color: #e4cff9;
	color: #867efc;
}
.avatar-pink {
	background-color: #fcd3e1;
	color: #ec82ef;
}
.avatar-red {
	background-color: #f0bcbc;
	color: #c21a1a;
}
.avatar-orange {
	background-color: #fee0c7;
	color: $orange;
}
.avatar-yellow {
	background-color: #fbedb7;
	color: #ecb403;
}
.avatar-green {
	background-color: #cfeab3;
	color: $green;
}
.avatar-teal {
	background-color: #bfefea;
	color: #2bcbba;
}
.avatar-cyan {
	background-color: #b9e3ea;
	color: #17a2b8;
}
.avatar-white {
	background-color: white;
	color: $white;
}
.avatar-gray {
	background-color: #dbdde0;
	color: #868e96;
}
.avatar-gray-dark {
	background-color: #c2c4c6;
	color: $dark;
}
.avatar-azure {
	background-color: #c7e6fb;
	color: $azure;
}
.avatar-lime {
	background-color: #d7f2c2;
	color: $lime;
}
.avatar-group {
	.avatar {
		position: relative;
		z-index: 2;
		background: transparent !important;
		&:hover {
			z-index: 3;
		}
		+.avatar {
			margin-left: -0.8rem;
		}
	}
	.avatar-sm {
		font-size: .875rem;
		width: 32px;
		height: 32px;
	}
}
.indicators{
	.avatar{
		position: relative;
		
		&::after{
			content: "";
			position: absolute;
			background-color: $primary;
			border: 2px solid  $white;
			border-radius: 50%;
		}
		&.avatar-sm{
			&.rounded-circle{
				line-height: 1.7rem;

				&::after{
					height:	9px;
					width: 9px;
					bottom: 0px;
					right: -1px;
					line-height: 2.5em;
				}
			}
		}
		&.avatar-normal{
			&.rounded-circle{
				line-height: 2rem;

				&::after{
					bottom: 2px;
					height: 10px;
					width: 10px;
					right: -3px;
				}
			}
		}
		&.avatar-md{
			&.rounded-circle{
				&::after{
					height: 11px;
					width: 11px;
					bottom: 3px;
					right: -1px;
				}
			}
		}
		&.avatar-lg{
			&.brround{
				&::after{
					height: 12px;
					width: 12px;
					bottom: 1px;
					right: -4px;
				}
			}
			&.rounded-circle{
				&::after{
					height: 12px;
					width: 12px;
					bottom: 4px;
					right: -1px;
				}
			}
			&::after{
				height: 12px;
				width: 12px;
				bottom: 0px;
				right: -5px;
			}
		}
		&.avatar-xl{
			&.rounded-circle{
				font-size: 26px;
	
				&::after{
					height: 13px;
					width: 13px;
					bottom: 5px;
					right: -1px;
				}
			}
		}
		&.avatar-xxl{
			&.rounded-circle{
				font-size: 28px;

				&::after{
					height: 15px;
					width: 15px;
					bottom: 6px;
					right: -1px;
				}
			}
		}
		&.offline::after{
			background-color: $muted;
		}
	}
}

.avatar-container{
	position: relative;

	.number-badge{
		position: absolute;
		top: -4%;
		padding: 10px;
		right: -6px;
		font-size: 10px !important;
		border: 2px solid $white;
		border-radius: 50% !important;
		height: 10%;
		width: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.avatar-icon-badge{
		position: absolute;
		border:2px solid $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 9%;
		left: 66%;
		cursor: pointer;
	}
	

	&.avatar-sm{
		.number-badge{
			right: -14px;
			top: -8px;
			padding: 9px;
		}
		.avatar-icon-badge{
			bottom: -13%;
			padding: 2px !important;
		}
	}
	&.avatar-md{
		.number-badge{
			top: -6px;
			right: -11px;
		}
		.avatar-icon-badge{
			bottom: -5%;
			padding: 3px !important;
			i{
				font-size: 11px;
			}
		}
	}
	&.avatar-lg{
		.number-badge{
			top: -5px;
			right: -10px;
		}
		.avatar-icon-badge{
			bottom: -4%;
			padding: 3px !important;
			i{
				font-size: 14px;
			}
		}
	}
	&.avatar-xl{
		.number-badge{
			top: -2px;
			right: 0;
		}
		.avatar-icon-badge{
			bottom: 0;
			padding: 3px !important;
			i{
				font-size: 16px;
				padding: 2px;
			}
		}
	}
}